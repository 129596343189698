import { myAxios } from "./axios";


export function getStudentGraph(studId){
    return myAxios.get('/'+studId+"/studentPercentage")
}

export function getExamGraph(examId){
    return myAxios.get('/'+examId+"/exam/performance/teacher")
}

export function getAllStudentsGraph(){
    return myAxios.get("/studentsPerformanceOverAll")
}

export function getTopWrongQuestions(examId){

    return myAxios.get('/'+examId+"/exam/wrongAnsweredQuesions")

}
  
export function getMyStudents(config){
    const headers = {
        params: config
    }
    return myAxios.get("/exams/myStudents", headers)

}