import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    isLogged: localStorage.getItem('access_token') ? true : false,
    user: {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginHandler(state,data){
        state.isLogged = true
        localStorage.setItem('access_token',data.payload)
    },
    logoutHandler(state){
        localStorage.removeItem('access_token')
        state.isLogged = false
        localStorage.removeItem('user')

    },
  },
})

export const authActions = authSlice.actions

export default authSlice.reducer
