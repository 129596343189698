import * as React from 'react';
import { Button } from '@mui/material';
import { deleteLevel, getLevels } from '../../api/levels';
import { useNavigate } from 'react-router-dom';
import ListTable from '../../shared-components/ListTable';

const columns = [
  { id: 'id', label: 'Id', minWidth: 170 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'action',label: 'Action',minWidth: 170,align: 'center' },
];

export default function LevelsList() {

  const navigate = useNavigate()
  
  return (
    <div className='page'>
      
      <div className='header'>
        <h1>Levels</h1>
        <Button size="large" variant="contained" onClick={() => {navigate('/add-level')}}>
          Add
        </Button>
      </div>

      <ListTable api={getLevels} deleteApi={deleteLevel} hasEdit={true} hasDelete={true} columns={columns} type="level" />   
         
    </div>
    
  );
}
