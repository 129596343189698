import { Alert } from "@mui/material";

import "./Loading.css"

export default function ResponseHandler(props){

    return (
        <>
            { props.loading &&
                <div className="loading">
                    <span className="loader"></span>
                </div>
            }
            { props.error &&
                <Alert severity="error">{props.error}</Alert>
            }
            { props.success &&
                <Alert severity="success">{props.successMsg}</Alert>
            }
            { props.empty &&
                <Alert severity="info">{props.emptyMsg}</Alert>
            }
        </>
    )

}