import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useCrud from "../../hooks/useCrud";
import { addTeacherExam } from "../../api/exams";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { useEffect, useState } from "react";
import SearchSelectBox from "../../shared-components/SearchSelectBox";
import { getCurriculums } from "../../api/curriculum";
import moment from "moment";

export default function AddTeacherExam() {
  const navigate = useNavigate();

  const { loading, error, success, setData } = useCrud(addTeacherExam, () => {
    navigate("/teacher-exams");
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    setValue('expiration_date', null)
  },[])

  const onSubmit = (formData) => {
    let date;
    if(formData.expiration_date && formData.expiration_date != "closed"){
      date = new Date(formData.expiration_date)
      date= moment(date).utc().format("YYYY-MM-DD HH:mm:ss")
    }else if(formData.expiration_date == "closed"){
      date="closed"
    }else{
      date=null
    }
    setData({...formData,curriculum_id: curriculum.id, expiration_date: date})
    
  };

  const [curriculum, setCurriculum] = useState(null);
  
  return (
    <div className="page">
      <div className="header">
        <h1>Add Exam</h1>
      </div>

      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{marginBottom: "15px"}}>
              <TextField
                size="small"
                sx={{ width: "100%" }}
                label="Name"
                {...register("name", { required: true })}
              />
              <div className="validationError">
                {errors.name?.type === "required" && "Name is required"}
              </div>
            </div>
            <SearchSelectBox
              multiple={false}
              api={getCurriculums}
              register={register}
              value={curriculum}
              handleChange={(event, newValue) => {
                setCurriculum(newValue);
              }}
              label="Curriculum"
              errors={errors}
            />
          </Grid>

          <Grid item xs={6}>
              <ButtonGroup disableElevation color='info' size="medium" style={{width: "100%", marginBottom: 15}}>
                <Button onClick={() => {setValue('expiration_date', null)}} variant={watch('expiration_date') == null ? "contained" : "outlined"} style={{width: "100%"}}>Open exam</Button>
                <Button onClick={() => {setValue('expiration_date', "closed")}} variant={watch('expiration_date') == "closed" ? "contained" : "outlined"} style={{width: "100%"}}>Close exam</Button>
                <Button onClick={() => {setValue('expiration_date',false)}} variant={![null, "closed"].includes(watch('expiration_date')) ? "contained" : "outlined"} style={{width: "100%"}}>Scheduled exam</Button>
              </ButtonGroup>
              {![null, "closed"].includes(watch('expiration_date')) && <div style={{marginBottom: "15px"}}>
                <TextField
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  sx={{ width: "100%" }}
                  label="Expiration Date"
                  {...register("expiration_date", { required: true })}
                />
                <div className="validationError">
                  {errors.expiration_date?.type === "required" && "Expiration Date is required"}
                </div>
              </div>}
            </Grid>
         
          <Grid item xs={12}>
            <ResponseHandler
              error={error}
              success={success}
              successMsg="Stored Successfully."
            />
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <FormButton txt="Create" loading={loading} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
