import { useState, useEffect } from "react"

export default function usePagination(api, data=null){

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [response, setResponse] = useState(null)
    const [page, setPage] = useState(0)
    const [payload, setPayload] = useState(data)
    const [reload, setReload] = useState(0)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {

        setLoading(true)

        api({page: page+1, q: payload}).then((res) => {
            setResponse(res)
            setLoading(false)
        }, (err) => {
            setLoading(false)
            setError('Something Went Wrong!')
        })

    },[page, payload, reload])

    return {response,error,loading, page, handleChangePage, setPayload,reload, setReload}

}