import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { editCurriculum, getCurriculum } from "../../api/curriculum";
import SearchSelectBox from "../../shared-components/SearchSelectBox";
import { getBoardLevels, getBoards } from "../../api/boards";
import { getSubjects } from "../../api/subjects";
import { getGrades } from "../../api/grades";
import AddIcon from '@mui/icons-material/Add';
import Board from 'react-trello'
import { TopicCard, LaneHeader } from "./ChapterLane";
import "./trelloBoard.css"

export default function EditCurriculum(){

    const navigate = useNavigate();

     // data

     const [board,setBoard] = useState(null)
     const [type,setType] = useState('level')
     const [subject,setSubject] = useState(null)
     const [level,setLevel] = useState(null)
     const [grade,setGrade] = useState(null)

     // chapters

     const handleAddChapter = () => {
        setChapters((ch) => ({
            lanes: [
                ...ch.lanes, 
                {
                    id: "chapter"+lastId.toString(),
                    title: '',
                    handleAddTopic,
                    cards: []
                },
            ]
        }))
        setLastId(lastId+1)
    }

    const handleAddTopic = (id) => {
        setChapters((ch) => ({
            lanes: ch.lanes.map((l) => {
                if(l.id == id){
                    return {...l, cards: l.cards.concat([{id: id+"-"+l.cards.length.toString(), title: ""}])}
                }else{
                    return l
                }
            })
        }))
    }
    
    const [chapters,setChapters] = useState({
        lanes: []
    })

    const [lastId,setLastId] = useState(0)
    
    const {curriculumId} = useParams()

    const {response: curriculumResponse,loading: curriculumLoading,error: curriculumError,setData: setCurriculumId} = useCrud(getCurriculum,(res) => {
        setBoard(res.data.board)
        setSubject(res.data.subject)
        if( res.data.gradable_type === "App\\Models\\Level" ){
            setType('level')
            setLevel(res.data.gradable)
        }else{
            setType('grade')
            setGrade(res.data.gradable)
        }
        if(res.data.chapters.length > 0){
            setLastId(res.data.chapters[res.data.chapters.length-1].id+1)
        }
        setChapters({
            lanes: res.data.chapters.map((c) => {
                return {id: "chapter"+c.id.toString(),title: c.name,handleAddTopic,cards: c.sections.map((d) => {return {...d,id: c.id.toString()+"-"+d.id.toString()}})}
            })
        })
    })

    useEffect(() => {
        setCurriculumId({id: curriculumId})
    },[])

    const { register, formState: { errors }, handleSubmit } = useForm();

    const {loading,error,success,setData} = useCrud(editCurriculum,() => {
        navigate("/curriculums")
    })

    const onSubmit = data => {
        let x = null;
        if( type === 'level' ){
            x = {level_id: level.id}
        }else{
            x = {grade_id: grade.id}
        }
        setData(
            {
                data: {
                    name: data.name ? data.name : board.name+"-"+subject.name+"-"+(type==="level" ? level.name : grade.name),
                    board_id: board.id,
                    subject_id: subject.id,
                    chapters: chapters.lanes.map(
                        (l,i) => {
                            return {
                                name: l.title,
                                order: i,
                                sections: l.cards.map(c => {return {title: c.title}})
                            }
                        }
                    ),
                    ...x
                }, 
                id: curriculumId
            }
        )
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Edit Curriculum</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                { curriculumResponse &&
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                        size="small"
                        defaultValue={curriculumResponse.data.name}
                            sx={{width: "100%"}}
                            label="Name"
                            {...register("name", { required: false})}
                        /> 
                    </Grid>
                    <Grid item xs={6}>
                        <SearchSelectBox 
                            multiple={false}
                            api={getBoards}
                            label="Board"
                            errors={errors}
                            register={register}
                            handleChange={(event, newValue) => {
                                setBoard(newValue)
                                setLevel(null)
                            }}
                            value={board}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SearchSelectBox 
                            multiple={false}
                            api={getSubjects}
                            label="Subject"
                            errors={errors}
                            register={register}
                            handleChange={(event, newValue) => {
                                setSubject(newValue)
                            }}
                            value={subject}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                size="small"
                                value={type}
                                label="Type"
                                onChange={
                                    (e) => {
                                        setType(e.target.value)
                                        setGrade(null)
                                        setLevel(null)
                                    }
                                }
                            >
                                <MenuItem value={"level"}>Level</MenuItem>
                                <MenuItem value={"grade"}>Grade</MenuItem>
                            </Select>
                        </FormControl>
                        
                    </Grid>
                    <Grid item xs={6}>
                        { type === 'level' && board &&
                            <SearchSelectBox 
                            multiple={false}
                            api={getBoardLevels}
                            payload={board.id}
                            label="Level"
                            errors={errors}
                            register={register}
                            handleChange={(event, newValue) => {
                                setLevel(newValue)
                            }}
                            value={level}
                        />
                        }
                        { type === 'grade' &&
                            <SearchSelectBox 
                            multiple={false}
                            api={getGrades}
                            label="Grade"
                            errors={errors}
                            register={register}
                            handleChange={(event, newValue) => {
                                setGrade(newValue)
                            }}
                            value={grade}
                        />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{marginBottom: "10px",display: "flex", alignItems: "center"}}>
                            <IconButton variant="contained" sx={{marginRight: "10px"}} onClick={handleAddChapter}>
                                <AddIcon />
                            </IconButton>
                            Chapters
                        </Typography>
                        <Board 
                            draggable={true}
                            //editable={true}
                            //collapsibleLanes={true}
                            style={{ height: "fit-content",background: "none", padding: "0px", width: "100%"}} 
                            //laneStyle={{margin: "0px", width: "100%",background: "none", padding: "0px"}} 
                            //cardStyle={{width: "25%", margin: "0px"}}
                            laneStyle={{ width: "350px",padding: "25px", backgroundColor: "#fbfbfb"}}
                            data={chapters} 
                            components={{
                                Card: TopicCard,
                                LaneHeader: LaneHeader
                            }} 
                            onDataChange={(newData) => {
                                setChapters(newData)
                            }} 
                        />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Update" loading={loading} />
                    </Grid>

                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Updated Successfully." />
                    </Grid>

                </Grid>
                }

                <ResponseHandler error={curriculumError} loading={curriculumLoading} />

            </Box>

        </div>
    )

}