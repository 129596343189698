import { myAxios } from "./axios";


export function getBoards(config){
    const headers = {
        params: config
    }
    return myAxios.get('/boards',headers)
}

export function getBoard(data){
    return myAxios.get('/board/'+data.id+'/show')
}

export function addBoard(data){
    return myAxios.post('/board/create',data)
}

export function editBoard(data){
    return myAxios.put('/board/'+data.id+'/update',data.data)
}

export function deleteBoard(data){
    return myAxios.delete('/board/'+data.id+'/delete')
}

export function getBoardLevels(config){
    const headers = {
        params: {
            page: config.page,
            q: config.q.q
        }
    }
    return myAxios.get("/level/"+config.q.id+"/getLevelsForBoard",headers)
}