import { useEffect, useState } from "react"


export default function useCrud(api, handleSuccess){

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')
    const [response, setResponse] = useState(null)

    const [data,setData] = useState(null)

    useEffect(() => {

        if(data){
            setLoading(true)
            api(data).then((res) => {
                setResponse(res)
                setSuccess(true)
                setLoading(false)
                setError('')
                handleSuccess(res)
            }, (err) => {
                setLoading(false)
                setSuccess(false)
                if(typeof err.response.data === 'string'){
                    setError(err.response.data)
                }else{
                    setError(err.response.data.message + " - File: " + err.response.data.file)
                }
            })

        }

    },[data])
    
    return {loading,success,error,response,setData}

}