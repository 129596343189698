import { Box, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { addGrade } from "../../api/grades";


export default function AddGrade(){

    const navigate = useNavigate();

    const {loading,error,success,setData} = useCrud(addGrade,() => {
        navigate("/grades")
    })

    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = data => {
        setData(data)
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Add Grade</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                        size="small"
                            sx={{width: "100%"}}
                            label="Name"
                            {...register("name", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.name?.type === 'required' && "Name is required"}</div>
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Create" loading={loading} />
                    </Grid>

                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Stored Successfully." />
                    </Grid>

                </Grid>

                </Box>

        </div>
    )

}