import * as React from 'react';
import { Button } from '@mui/material';
import { deleteSubject, getSubjects } from '../../api/subjects';
import { useNavigate } from 'react-router-dom';
import ListTable from '../../shared-components/ListTable';
import { deleteQuestion, getQuestions } from '../../api/questions';

const columns = [
  { id: 'id', label: 'Id', minWidth: 170 },
  { id: 'img_url', label: 'Image',type: 'img', minWidth: 120 },
  {id: "title", label: "Title", minWidth: 120},
  { id: 'action',label: 'Action',minWidth: 170,align: 'center' },
];

export default function AllQuestionsList() {
  
  return (
    <div className='page'>
      
      <div className='header'>
        <h1>Questions</h1>
      </div>

      <ListTable api={getQuestions} deleteApi={deleteQuestion} hasDelete={true} columns={columns} type="question" />   
         
    </div>
    
  );
}
