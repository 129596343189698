import { myAxios } from "./axios"


export function getQuestions(config){
    const headers = {
        params: config
    }
    return myAxios.get('/questions',headers)
}

export function editQuestion(data){
    return myAxios.post('/question/'+data.id+'/update', data.data)
}
export function deleteQuestion(data){
    return myAxios.delete('/question/'+data.id+'/delete')
}