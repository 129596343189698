import { myAxios } from "./axios";


export function getCurriculums(config){
    const headers = {
        params: config
    }
    return myAxios.get('/curriculums',headers)
}

export function getCurriculum(data){
    return myAxios.get('/curriculum/'+data.id+'/show')
}

export function addCurriculum(data){
    return myAxios.post('/curriculum/create',data)
}

export function editCurriculum(data){
    return myAxios.put('/curriculum/'+data.id+'/update',data.data)
}

export function deleteCurriculum(data){
    return myAxios.delete('/curriculum/'+data.id+'/delete')
}