import React from 'react';
import './App.css';
import Auth from './pages/auth/Auth';
import Layout from './pages/layout/Layout'
import { useSelector } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {

  let loggedIn = useSelector((state) => state.auth.isLogged);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FF3D00',
      },
      secondary: {
        main: '#880e4f',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {
        loggedIn &&
        <Layout />
      }
      {
        !loggedIn &&
        <Auth />
      }
    </ThemeProvider>
  );
}

export default App;
