import {Box, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import { useParams } from "react-router";
import { useEffect } from "react";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { editTeacher, getTeacher } from "../../api/teachers";
import { useState } from "react";
import SearchSelectBox from "../../shared-components/SearchSelectBox";
import { getSubjects } from "../../api/subjects";


export default function EditTeacher(){

    const navigate = useNavigate();


    const {teacherId} = useParams()
    const [subject, setSubject] = useState(null)

    const {loading,error,success,setData} = useCrud(editTeacher,() => {
        navigate("/teachers")
    })

    const {response: teacherResponse,loading: teacherLoading,error: teacherError,setData: setTeacherId} = useCrud(getTeacher,(res) => {
        setSubject(res.data.subject)
    })

    useEffect(() => {
        setTeacherId({id: teacherId})
    },[])

    

    const { register, formState: { errors }, handleSubmit } = useForm();


    const onSubmit = data => {
        setData({data: {...data, subject_id: subject.id}, id: teacherId})
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Edit Teacher</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                { teacherResponse &&
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                        size="small"
                        defaultValue={teacherResponse.data.name}
                            sx={{width: "100%"}}
                            label="Name"
                            {...register("name", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.name?.type === 'required' && "Name is required"}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField    
                            defaultValue={teacherResponse.data.email}
                            size="small"
                            sx={{width: "100%"}}
                            label="Email"
                            {...register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })}
                        /> 
                        
                        <div className="validationError">{errors.email?.type === 'pattern' && "Email is not valid" } {errors.email?.type === 'required' && "Email is required" } </div>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField                         
                            size="small"
                            type="password"
                            sx={{width: "100%"}}
                            label="New Password"
                            {...register("password")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SearchSelectBox 
                            multiple={false}
                            api={getSubjects}
                            label="Subject"
                            errors={errors}
                            register={register}
                            handleChange={(event, newValue) => {
                                setSubject(newValue)
                            }}
                            value={subject}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Update" loading={loading} />
                    </Grid>

                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Updated Successfully." />
                    </Grid>

                </Grid>
                }

                <ResponseHandler error={teacherError} loading={teacherLoading} />

            </Box>

        </div>
    )

}