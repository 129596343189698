import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import usePagination from "../hooks/usePagination";

export default function SearchSelectBox(props){

    const {response: boardsResponse, loading: boardsLoading, error: boardsError, setPayload} = usePagination(props.api,(props.payload ? {id: props.payload} : null))

    const [searchTxt, setSearchTxt] = useState('')

    useEffect(() => {
        if( props.payload ){
            setPayload({q: searchTxt, id: props.payload})
        }else{
            setPayload(searchTxt)
        }
    },[searchTxt, props.payload])

return (
    <>
        { boardsResponse &&
        <>
        {!props.noValidate && 
            <>
                <Autocomplete
                    size="small"
                    {...props.register(props.name ? props.name : props.label, { validate: 
                        () => {
                            if( props.value ){
                                if( Array.isArray(props.value) ){
                                    return props.value.length > 0
                                }
                            }else{
                                return false
                            }
                        } 
                    })}
                    disabled={props.disabled}
                    multiple={props.multiple}
                    value={props.value}
                    onChange={(event, newValue) => {
                        props.handleChange(event, newValue);
                    }}
                    getOptionLabel={(option) => props.searchLabel ? option[props.searchLabel] : option.name}
                    //isOptionEqualToValue={(option) => option.id}
                    options={boardsResponse.data.data}
                    renderInput={(params) => <TextField value={searchTxt} onChange={(e) => {setSearchTxt(e.target.value)}} {...params} label={props.label} />}
                />
                <div className="validationError">{props.errors[props.name ? props.name : props.label]?.type === 'validate' && (!props.value || props.value.length === 0) && props.label +" is required"}</div>
    
            </>
        }

        {props.noValidate && 
        <Autocomplete
            size="small"
            multiple={props.multiple}
            value={props.value}
            onChange={(event, newValue) => {
                props.handleChange(event, newValue);
            }}
            getOptionLabel={(option) => props.searchLabel ? option[props.searchLabel] : option.name }
            options={boardsResponse.data.data}
            renderInput={(params) => <TextField value={searchTxt} onChange={(e) => {setSearchTxt(e.target.value)}} {...params} label={props.label} />}
        />}
        
    </>
    }
    </>
)


}