

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function DeleteModal(props) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button color={props.color?"info":"error"} sx={props.color ? {color:"black"}: {}} onClick={handleClickOpen}>
        Delete
      </Button>
      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        sx={{textAlign: "center"}}
      >
        <DialogTitle sx={{paddingTop: "35px"}}>
          <ErrorOutlineOutlinedIcon sx={{fontSize: "100px", color:"orange",display: "block",margin: "0px auto 15px auto !important"}} />
          {"Are You Sure ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you relly want to delete this {props.type} ? 
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center",paddingBottom: "35px"}}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="error" onClick={() => {
            props.handleConfirm()
            handleClose()
            }}>Confirm</Button>
        </DialogActions>
      </Dialog>
      </React.Fragment>
  );
}
