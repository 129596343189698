import { Box, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import { getBoards } from "../../api/boards";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { editSubject, getSubject } from "../../api/subjects";
import SearchSelectBox from "../../shared-components/SearchSelectBox";
import ChooseImage from "../../shared-components/ChooseImage";


export default function EditSubject(){

    const {subjectId} = useParams()

    const navigate = useNavigate();
    
    // boards
    const [boards, setBoards] = useState([])

    /// image
    const [image,setImage] = useState(null)

    const {loading,error,success,setData} = useCrud(editSubject,() => {
        navigate("/subjects")
    })

    const {response: subjectResponse,loading: subjectLoading,error: subjectError,setData: setSubjectId} = useCrud(getSubject,(res) => {
        setBoards(res.data.boards)
        setImage(res.data.img_url)
    })


    useEffect(() => {
        setSubjectId({id: subjectId})
    },[])

    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = formData => {
        const data = new FormData()
        data.append('name',formData.name)
        if( typeof image != "string" ){
            data.append('image',image)
        }
        boards.map((b,i) => {
            data.append('boards_ids['+i+']',b.id)
        })
        setData({data, id: subjectId})
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Edit Subject</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                { subjectResponse &&
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            defaultValue={subjectResponse.data.name}
                            sx={{width: "100%"}}
                            label="Name"
                            {...register("name", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.name?.type === 'required' && "Name is required"}</div>
                        <div style={{marginBottom: "15px"}}></div>
                        <SearchSelectBox 
                            multiple={true}
                            api={getBoards}
                            register={register}
                            value={boards}
                            handleChange={(event, newValue) => {
                                setBoards(newValue);
                            }}
                            label="Board"
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ChooseImage 
                        {...register('image', { validate: 
                            () => {
                                return image ? true : false
                            } 
                        })}
                        image={image ? (typeof image === 'string' ? image : URL.createObjectURL(image)) : null} handleSelectImage={(file) => {
                            setImage(file)
                        }} />
                        <div className="validationError">{errors.image?.type === 'validate' && !image && "Image is required"}</div>
                    </Grid>

                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Updated Successfully." />
                    </Grid>

                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Update" loading={loading} />
                    </Grid>

                </Grid>
                }

                <ResponseHandler error={subjectError} loading={subjectLoading} />

            </Box>

        </div>
    )

}