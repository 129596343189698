import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Alert, Box, Button, ButtonGroup, IconButton, Link, Paper, Popper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import usePagination from '../hooks/usePagination';
import DeleteModal from './DeleteModal';
import useCrud from "../hooks/useCrud"
import "./ListTable.css"
import ResponseHandler from './ResponseHandler';
import { CSVLink, CSVDownload } from "react-csv";
import image from "../assets/imgs/placeholder-image.webp"
import QuestionImageModal from '../pages/questions/QuestionImageModal';
import {useState} from 'react';
import Fade from '@mui/material/Fade';
import { MoreHorizOutlined } from '@mui/icons-material';
import TableCompressActionCol from './TableCompressActionCol';





export default function ListTable(props){

    const {response,error,loading, page, handleChangePage, reload,setReload} = usePagination(props.api,props.apiMeta && {id: props.apiMeta})

    const navigate = useNavigate()

    const {loading: deleteLoading, success: deleteSuccess, error: deleteError, setData: setDeleteId} = useCrud(props.deleteApi,() => {
      setReload(reload+1)
    }) 

    function handleConfirmDelete(id){
      setDeleteId({id})
    }

    //////////////
    
    const [open, setOpen] = useState(false);
    const [img, setImg] = useState(null)

    const handleClose = () => {
      setOpen(false);
    };

    ////////////


    return (
        <>
        
      { response && !loading && response.data.data.length > 0 &&
        <>
            {props.hasExport && 

                <CSVLink 
                  filename={"students.csv"}
                  style={{textDecoration: "none"}}
                  key={"export-"+props.apiMeta}
                  data={[
                    ["Id", "Student", "Full name", "Email","Score"],
                    ...response.data.data.map(stud => {
                      return [stud.user.id, stud.user.name, stud.full_name, stud.user.email, stud.score]
                    })
                  ]}>
                           <Button sx={{marginBottom: "25px"}} color="success" variant="contained">  
                                Export To EXcel
                            </Button>
                  </CSVLink>
                
              }
          <TableContainer>
        <Table stickyHeader aria-label="sticky table" sx={{border: "1px solid #e0e0e0"}}>
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.label}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { 
            response.data.data.map((row, i) => {

              return (
                  <TableRow hover key={row.id+"-"+i}>
                    {props.columns.map((column) => {
                      if( column.id !== "action" ){
                        let value = null;
                        if (column.getter) {
                          value = column.getter(row);
                        } else {
                          value = column.subId
                            ? row[column.id]
                              ? row[column.id][column.subId]
                              : null
                            : row[column.id];
                        }
                      return (
                          <TableCell key={column.id+column.subId+i} align={column.align}>
                            <Box
                                component={column.href ? Button : "div"}
                                href={column.href}
                                onClick={() => {
                                  if(column.href){
                                    navigate(column.href(column.hrefAttr ? row[column.hrefAttr] : row.question_id))
                                  }
                                }}>
                                  {column.type === 'img' ? <img onClick={() => {
                                  setOpen(true)
                                  setImg(value ? value : image)
                                }} style={{width: "50px", cursor: "pointer"}} src={value ? value : image} /> : value}
                                    </Box>
                          </TableCell>
                        );
                      }
                    })}
                     <TableCell align="center">
                        {!props.compressBtns && <ButtonGroup
                        variant="contained"
                      >
                        { props.hasEdit &&
                            <Button color='info' onClick={() => {navigate("/edit-"+props.type+"/"+row['id'])}}>  
                                Edit
                            </Button>
                        }
                        { props.hasDelete &&
                            <DeleteModal type={props.type} handleConfirm={() => {
                              handleConfirmDelete(row['id'])
                            }} />
                        }
                        { props.additionalBtnUrl &&
                          <Button color="success" onClick={() => {navigate("/"+props.additionalBtnUrl+"/"+row['id'])}}>  
                                {props.additionalBtnUrl.replace("-", ' ')}
                            </Button>
                        }
                       { props.additionalBtnUrl2 &&
                            <Button color="info" onClick={() => {navigate("/"+props.additionalBtnUrl2+"/"+row['id'])}}>  
                                {props.additionalBtnUrl2.replace("-", ' ')}
                            </Button>
                        }
                        { props.modalBtn &&
                          <>
                          
                          {((props.modalBtn.retakeBtn && row.status != 'retake')) && 
                          <Button color="info" onClick={() => {props.modalBtn.func(row)}}>  
                                {props.modalBtn.label}
                            </Button>}
                          </>
                        }
                        { props.statsBtn &&
                          <Button color="info" onClick={() => {props.statsBtn.func(row)}}>  
                                {props.statsBtn.label}
                            </Button>
                        }
                        </ButtonGroup>}
                        {props.compressBtns && 
                          <TableCompressActionCol row={row} handleConfirmDelete={handleConfirmDelete} {...props}  />
                        }
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100, response.data.per_page]}
        component="div"
        count={response.data.total} /** total items */
        rowsPerPage={response.data.per_page} /** page size */
        page={page} /** current page */
        onPageChange={handleChangePage}
        onRowsPerPageChange={() => {}}
        showLastButton={true}
        showFirstButton={true}
      />
        </>
      }
      
      <ResponseHandler emptyMsg={'No ' + props.type + "s found"} error={error} loading={loading} empty={response && response.data.data.length===0} />

           <QuestionImageModal 
                open={open}
                handleClose={handleClose}
                img={img}
              />     
        </>
    )
    

}