import { myAxios } from "./axios";


export function login(data){
    return myAxios.post('/login/admin',data)
}


export function addNotifi(data){
    return myAxios.post('/notify/all',data)
}