import { Alert, Box, Button, Grid, Paper, TextField } from "@mui/material";
import "../Auth.css"
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from "../../../store/auth";
import { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import { login } from "../../../api/auth";
import FormButton from "../../../shared-components/FormButton";
import ResponseHandler from "../../../shared-components/ResponseHandler";
import logo from "../../../assets/imgs/logo.png"
export default function Login(){

    let router = useNavigate()

   const { register, formState: { errors }, handleSubmit } = useForm();

    const auth = useDispatch()

    const [loading,setLoading] = useState(false)
    const [success,setSuccess] = useState(false)
    const [error,setError] = useState('')

    const onSubmit = data => {
        setLoading(true)
        login(data).then(({data}) => {
            setLoading(false)
            setSuccess(true)
            router(data.admin.type== "teacher" ? "/teacher-exams" : "/exams")  
            setError('')
            auth(authActions.loginHandler(data.token))
            localStorage.setItem('user', JSON.stringify(data.admin))

        }, (err) => {
            setLoading(false)
            setSuccess(false)
            setError(err.response.data.message)
        })
    };

    return(

        <div className="authPage">
            <div className="overlay">

                <Paper className="authBox">

                    <img src={logo} style={{width: "90px"}} />
                    <h2 style={{marginBottom: 35}}>Login</h2>

                    <Box autoComplete="off">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        sx={{width: "100%"}}
                                        label="Email"
                                        {...register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })}
                                    /> 

                                    <div className="validationError">
                                    {errors.email?.type === 'required' && "Email is required"}
                                    </div>                            
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    type="password"
                                        sx={{width: "100%"}}
                                        label="Password"
                                        {...register("password", { required: true })}
                                    />
                                    <div className="validationError">
                                    {errors.password?.type === 'required' && "Password is required"}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <ResponseHandler error={error} success={success} successMsg={"Logging In..."} />
                                </Grid>
                                <Grid item xs={4} sx={{margin: "auto"}}>
                                    <FormButton txt="Login" loading={loading} />
                                </Grid>
                            
                            </Grid>
                        </form>
                    </Box>
                </Paper> 

            </div>
        </div>

    )

}