import { Route, Routes } from 'react-router';
import PageNotFound from '../PageNotFound';
import Login from './login/Login';
import Register from './login/Register';
import { Navigate } from 'react-router-dom';


export default function Auth(){

    return(
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    )

}