

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function QuestionImageModal(props) {


  return (
      
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        sx={{textAlign: "center"}}
        scroll="body"
      >
      
                <img src={props.img} style={{width: "100%", padding: 20}}  />

        <DialogActions sx={{justifyContent: "center"}}>
          <Button onClick={props.handleClose}>Close</Button>
        
        </DialogActions>
      </Dialog>
  );
}
