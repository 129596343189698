import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useCrud from "../../hooks/useCrud";
import { editTeacherExam, getTeacherExam } from "../../api/exams";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { useState, useEffect } from "react";
import SearchSelectBox from "../../shared-components/SearchSelectBox";
import { getCurriculums } from "../../api/curriculum";
import moment from "moment-timezone";

export default function EditTeacherExam() {

  
  // get exam

  const { examId } = useParams();
  const {
    loading: examLoading,
    error: examError,
    response: examData,
    setData: setExamId,
  } = useCrud(getTeacherExam, (res) => {
    setCurriculum(res.data.curriculum);
    if(res.data.expiration_date){
      if( new Date(res.data.expiration_date + " UTC")< new Date()){
        setValue('expiration_date', "closed")
      }else{
        setValue('expiration_date', moment(new Date(res.data.expiration_date+" UTC")).format("YYYY-MM-DD HH:mm:ss"))
      }
    }else{
      setValue('expiration_date', null)
    }
   
    //setQuestions(res.data.questions_for_exam);
  })

  useEffect(() => {
    setExamId({ id: examId });
  }, []);

  // submit
  const { loading, error, success, setData } = useCrud(editTeacherExam);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm();

  const onSubmit = (formData) => {
    let date;
    if(formData.expiration_date && formData.expiration_date != "closed"){
      date = new Date(formData.expiration_date)
      date= moment(date).utc().format("YYYY-MM-DD HH:mm:ss")
    }else if(formData.expiration_date == "closed"){
      date="closed"
    }else{
      date=null
    }
    setData({
      
        ...formData, 
        expiration_date: date,
        //year: formData?.year?.toString(),
        curriculum_id: curriculum.id, 
        id: examId
        //questions_ids: questions.map(q => q.id)
      
    })
  };

  const [curriculum, setCurriculum] = useState(null);

  //const [questions, setQuestions] = useState([])


  
  // years

  /*function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 22
    var years = []
  
    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }*/

  return (
    <div className="page">
      <div className="header">
        <h1>Edit Exam</h1>
      </div>

      {examData && (
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={{marginBottom: "15px"}}>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  label="Name"
                  defaultValue={examData.data.name}
                  {...register("name", { required: true })}
                />
                <div className="validationError">
                  {errors.name?.type === "required" && "Name is required"}
                </div>
              </div>
              <SearchSelectBox
                multiple={false}
                api={getCurriculums}
                register={register}
                value={curriculum}
                handleChange={(event, newValue) => {
                  setCurriculum(newValue);
                }}
                label="Curriculum"
                errors={errors}
              />
            </Grid>

            <Grid item xs={6}>
              <ButtonGroup disableElevation color='info' size="medium" style={{width: "100%", marginBottom: 15}}>
                <Button onClick={() => {setValue('expiration_date', null)}} variant={watch('expiration_date') == null ? "contained" : "outlined"} style={{width: "100%"}}>Open exam</Button>
                <Button onClick={() => {setValue('expiration_date', "closed")}} variant={watch('expiration_date') == "closed" ? "contained" : "outlined"} style={{width: "100%"}}>Close exam</Button>
                <Button onClick={() => {setValue('expiration_date',false)}} variant={![null, "closed"].includes(watch('expiration_date')) ? "contained" : "outlined"} style={{width: "100%"}}>Scheduled exam</Button>
              </ButtonGroup>
              {![null, "closed"].includes(watch('expiration_date')) && <div style={{marginBottom: "15px"}}>
                <TextField
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  sx={{ width: "100%" }}
                  label="Expiration Date"
                  {...register("expiration_date", { required: true })}
                />
                <div className="validationError">
                  {errors.expiration_date?.type === "required" && "Expiration Date is required"}
                </div>
              </div>}
            </Grid>

            <Grid item xs={12}>
              <ResponseHandler
                error={error}
                success={success}
                successMsg="Updated Successfully."
              />
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <FormButton txt="Update" loading={loading} />
            </Grid>
          </Grid>
        </Box>
      )}

      <ResponseHandler error={examError} loading={examLoading} />
    </div>
  );
}
