import * as React from 'react';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ListTable from '../../../shared-components/ListTable';
import { getChapters } from '../../../api/chapters';

const columns = [
  { id: 'id', label: 'Id', minWidth: 170 },
  { id: 'name', label: 'Name', minWidth: 100 },
];

export default function ChaptersList() {
  
  const {curriculumId} = useParams()

  return (
    <div className='page'>
      
      <div className='header'>
        <h1>Chapters</h1>
       
      </div>
      <ListTable api={getChapters} apiMeta={curriculumId} columns={columns} type="chapter" />   

         
    </div>
    
  );
}
