import { myAxios } from "./axios";


export function getExams(config){
    const headers = {
        params: config
    }
    return myAxios.get('/exams',headers)
}

export function getReports(config){
    const headers = {
        params: config
    }
    
    return myAxios.get('/questionReports',headers)
}

export function deleteReport(data){
    return myAxios.delete('/report/'+data.id+'/delete')
}

export function getExam(data){
    return myAxios.get('/exam/'+data.id+'/show')
}

export function addExam(data){
    return myAxios.post('/exam/create',data)
}

export function editExam(data){
    return myAxios.post('/exam/'+data.id+'/update',data.data)
}

export function deleteExam(data){
    return myAxios.delete('/exam/'+data.id+'/delete')
}

//teachers exams


export function getTeacherExams(config){
    const headers = {
        params: {
            page: config.page,
            ///teacher_id: JSON.parse(localStorage.getItem('user')).id
        }
    }
    return myAxios.get('/teacherExams',headers)
}

export function getTeacherExam(data){
    return myAxios.get('/teacherExam/'+data.id+'/show')
}

export function addTeacherExam(data){
    return myAxios.post('/teacherExam/create',data)
}

export function editTeacherExam(data){
    return myAxios.put('/teacherExam/'+data.id+'/update',data)
}

export function deleteTeacherExam(data){
    return myAxios.delete('/teacherExam/'+data.id+'/delete')
}

export function getExamStudents({page, q}){
    const headers = {
        params: {
            page: page,
        }
    }
    return myAxios.get('/teacherExam/'+q.id+'/getStudentsPerf', headers)
}
////////////////// 

export function getQuestion(data){
    
    return myAxios.get('/question/'+data.id+'/show')
}