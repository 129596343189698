import { Box, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { addTeacher } from "../../api/teachers";
import SearchSelectBox from "../../shared-components/SearchSelectBox";
import { getSubjects } from "../../api/subjects";
import { useState } from "react";


export default function AddTeacher(){

    const navigate = useNavigate();

    
    const {loading,error,success,setData} = useCrud(addTeacher,() => {
        navigate("/teachers")
    })

    const { register, formState: { errors }, handleSubmit } = useForm();

    const [subject, setSubject] = useState(null)

    const onSubmit = data => {
        setData({...data, subject_id: subject.id})
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Add Teacher</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                        size="small"
                            sx={{width: "100%"}}
                            label="Name"
                            {...register("name", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.name?.type === 'required' && "Name is required"}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField    
                                size="small"
                            sx={{width: "100%"}}
                            label="Email"
                            {...register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })}
                        /> 
                        
                        <div className="validationError">{errors.email?.type === 'pattern' && "Email is not valid" } {errors.email?.type === 'required' && "Email is required" } </div>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField                         
                        size="small"
                        type="password"
                            sx={{width: "100%"}}
                            label="Password"
                            {...register("password", { required: true })}
                        />
                        <div className="validationError">{errors.password?.type === 'required' && "Password is required"}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <SearchSelectBox 
                            multiple={false}
                            api={getSubjects}
                            label="Subject"
                            errors={errors}
                            register={register}
                            handleChange={(event, newValue) => {
                                setSubject(newValue)
                            }}
                            value={subject}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Create" loading={loading} />
                    </Grid>

                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Stored Successfully." />
                    </Grid>

                </Grid>

                </Box>

        </div>
    )

}