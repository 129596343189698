import { Alert, Box, Button, Grid, Paper, TextField } from "@mui/material";
import "../Auth.css"
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux'
import { authActions } from "../../../store/auth";
import { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import {register as registerApi} from '../../../api/auth'
import logo from "../../../assets/imgs/logo.png"
import FormButton from "../../../shared-components/FormButton";
import ResponseHandler from "../../../shared-components/ResponseHandler";
import { addTeacher } from "../../../api/teachers";
import SearchSelectBox from "../../../shared-components/SearchSelectBox";
import { getSubjects } from "../../../api/subjects";
export default function Register(){

    let router = useNavigate()

   const { register, formState: { errors }, handleSubmit } = useForm();

    const auth = useDispatch()

    const [loading,setLoading] = useState(false)
    const [success,setSuccess] = useState(false)
    const [error,setError] = useState('')
    const [subject, setSubject] = useState(null)

    const onSubmit = formData => {
        setLoading(true)

        addTeacher({...formData, subject_id: subject.id}).then(({data}) => {
            setLoading(false)
            setSuccess(true)
            setError('')
            router("/teacher-exams")
            auth(authActions.loginHandler(data.token))
            localStorage.setItem('user', JSON.stringify(data.teacher))

        }).catch(({response}) => {
            setError(response.data.message)
            setLoading(false)
        })
    };



    return(

        <div className="authPage">
            <div className="overlay" style={{paddingBottom: "80px"}}>

            <Paper className="authBox">

                <img src={logo} style={{width: "90px"}} />
                <h2 style={{marginBottom: 35}}>Register</h2>

                <Box autoComplete="off">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    sx={{width: "100%"}}
                                    label="Username"
                                    {...register("name", { required: true })}
                                /> 
                                <div className="validationError">
                                {errors.name?.type === 'required' && "Username is required"}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    sx={{width: "100%"}}
                                    label="Email"
                                    {...register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })}
                                /> 
                                <div className="validationError">
                                {errors.email?.type === 'required' && "Email is required"}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <SearchSelectBox 
                                    multiple={false}
                                    api={getSubjects}
                                    label="Subject"
                                    errors={errors}
                                    register={register}
                                    handleChange={(event, newValue) => {
                                        setSubject(newValue)
                                    }}
                                    value={subject}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                size="small"
                                type="password"
                                    sx={{width: "100%"}}
                                    label="Password"
                                    {...register("password", { required: true })}
                                />
                                <div className="validationError">
                                {errors.password?.type === 'required' && "Password is required"}
                                </div>
                            </Grid>
                        
                            <Grid item xs={12}>
                                <ResponseHandler error={error} success={success} successMsg={"Logging In..."} />
                            </Grid>
                            <Grid item xs={4} sx={{margin: "auto"}}>
                                <FormButton txt="Register" loading={loading} />
                                <a href="https://front.igcse.tk/login" style={{
                                     marginTop: "1rem",
                                     display: "block",
                                     color: "rgb(255, 61, 0)"
                                }}>Already have an account?</a>
                            </Grid>
                                
                        </Grid>
                    </form>
                </Box>
            </Paper> 

            </div>
        </div>

 
    )

}