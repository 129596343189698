import * as React from 'react';
import { Button } from '@mui/material';
import { deleteExam, getExams } from '../../api/exams';
import { useNavigate } from 'react-router-dom';
import ListTable from '../../shared-components/ListTable';

const columns = [
  { id: 'id', label: 'Id', minWidth: 100 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'month',label: 'Month',minWidth: 170,align: 'center' },
  { id: 'year',label: 'Year',minWidth: 170,align: 'center' },
  { id: 'curriculum',subId: "name", label: 'Curriculum',minWidth: 170,align: 'center' },
  { id: 'code', label: 'Code', minWidth: 100 },
  { id: 'action',label: 'Action',minWidth: 170,align: 'center' },
];

export default function ExamsList() {

  const navigate = useNavigate()
  
  return (
    <div className='page'>
      
      <div className='header'>
        <h1>Exams</h1>
        <Button size="large" sx={{marginBottom: "20px"}} variant="contained" onClick={() => {navigate('/add-exam')}}>
          Add
        </Button>
      </div>

      <ListTable api={getExams} deleteApi={deleteExam} hasEdit={true} hasDelete={true} columns={columns} type="exam" />   
         
    </div>
    
  );
}
