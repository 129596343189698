import { Box, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { addNotifi } from "../../api/auth";


export default function PushNotification(){

    const navigate = useNavigate();

    const {loading,error,success,setData} = useCrud(addNotifi,() => {
        
        setValue('title', '')
        setValue('description', '')

    })

    const { register, formState: { errors }, handleSubmit, setValue } = useForm();

    const onSubmit = data => {
        setData(data)
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Push Notification</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        size="small"
                            sx={{width: "100%"}}
                            label="Title"
                            {...register("title", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.title?.type === 'required' && "Title is required"}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            rows={4}
                            size="small"
                            sx={{width: "100%"}}
                            label="Description"
                            {...register("description", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.description?.type === 'required' && "Description is required"}</div>
                    </Grid>
                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Sent Successfully." />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Create" loading={loading} />
                    </Grid>

                    

                </Grid>

                </Box>

        </div>
    )

}