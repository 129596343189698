import * as React from 'react';
import {  getExamStudents } from '../../api/exams';
import {  useParams } from 'react-router-dom';
import ListTable from '../../shared-components/ListTable';
import { useState } from 'react';
import RetakeModal from './RetakeModal';


const columns = [
  { id: 'user', subId:"id", label: 'Id', minWidth: 50 },
  { id: 'user', subId:"name", label: 'Student', minWidth: 200 },
    { id: 'full_name', label: 'Full name', minWidth: 200 },

  { id: 'user', subId:"email", label: 'Email', minWidth: 200 },

  { id: 'score', label: 'Score', getter: (r) => r.score+' / '+r.totalQuestions, minWidth: 30, align : "center" },
  { id: 'status', label: 'Status', getter: (r) => r.status, minWidth: 30, align : "center" },

  { id: 'action', label: 'Action' , minWidth: 80, align : "center" }
];

export default function ExamStudentsList() {

  const {examId} = useParams()
  
  const [retakeOpen, setRetakeOpen] = useState(null)

  const [reload, setReload] = useState(0)

  return (
    <div className='page'>
      
      <div className='header'>
        <h1>Students</h1>
      </div>

      {/*

      <StatsGraph apiFunction={() => {
        return getTopWrongQuestions(430)
      }} />

      */}

      <ListTable 
      key={reload}
      modalBtn={
        {
            label: "Allow retake",
            retakeBtn: true,
            func: (stud) => {
                setRetakeOpen(stud.user)
            }
        }
      }
      hasExport={true} apiMeta={examId} api={getExamStudents} hasEdit={false} hasDelete={false} columns={columns} type="student" />   
         
      <RetakeModal 
        open={retakeOpen ? true : false}
        setOpen={setRetakeOpen}
        stud={retakeOpen}
        examId={examId}
        refetchTable={() => {
          setReload(reload+1)
        }}
      />

    </div>
    
  );
}



