import { Route, Routes } from 'react-router';

import BoardsList from '../boards/BoardsList';
import AddBoard from '../boards/AddBoard';
import EditBoard from '../boards/EditBoard';

import GradesList from '../grades/GradesList';
import AddGrade from '../grades/AddGrade';
import EditGrade from '../grades/EditGrade';

import LevelsList from '../levels/LevelsList';
import AddLevel from '../levels/AddLevel';
import EditLevel from '../levels/EditLevel';

import SubjectsList from '../subjects/SubjectsList';
import AddSubject from '../subjects/AddSubject';
import EditSubject from '../subjects/EditSubject';

import CurriculumList from '../curriculum/CurriculumList';
import AddCurriculum from '../curriculum/AddCurriculum';
import EditCurriculum from '../curriculum/EditCurriculum';

import ExamsList from '../exams/ExamsList';
import AddExam from '../exams/AddExam';
import EditExam from '../exams/EditExam';

import TeachersList from '../teachers/TeachersList';
import AddTeacher from '../teachers/AddTeacher';
import EditTeacher from '../teachers/EditTeacher';

import PageNotFound from '../PageNotFound';

import ChaptersList from '../curriculum/chapters/ChaptersList';
import TeacherExamsList from '../teacher-exams/TeacherExamsList';
import AddTeacherExam from '../teacher-exams/AddTeacherExam';
import EditTeacherExam from '../teacher-exams/EditTeacherExam';
import QuestionsList from '../teacher-exams/QuestionsList';
import ExamStudentsList from '../teacher-exams/ExamStudentsList';
import ReportsList from '../exams/ReportsList';
import QuestionView from '../exams/QuestionView';
import PushNotification from '../notification/PushNotification';
import AllQuestionsList from '../questions/QuestionsList';
import EditQuestion from '../questions/EditQuestion';
import StatsGraph from '../teacher-exams/StatsGraph';
import OverallStatsPage from '../teacher-exams/OverallStatsPage';
import ExamStats from '../teacher-exams/ExamStats';


export default function Content(){

    return(
        <Routes>
          
          <Route path="boards" element={<BoardsList />} />
          <Route path="add-board" element={<AddBoard />} />
          <Route path="edit-board/:boardId" element={<EditBoard />} />

          <Route path="grades" element={<GradesList />} />
          <Route path="add-grade" element={<AddGrade />} />
          <Route path="edit-grade/:gradeId" element={<EditGrade />} />

          <Route path="levels" element={<LevelsList />} />
          <Route path="add-level" element={<AddLevel />} />
          <Route path="edit-level/:levelId" element={<EditLevel />} />

          <Route path="subjects" element={<SubjectsList />} />
          <Route path="add-subject" element={<AddSubject />} />
          <Route path="edit-subject/:subjectId" element={<EditSubject />} />

          <Route path="curriculums" element={<CurriculumList />} />
          <Route path="add-curriculum" element={<AddCurriculum />} />
          <Route path="edit-curriculum/:curriculumId" element={<EditCurriculum />} />

          <Route path="chapters/:curriculumId" element={<ChaptersList />} />
        
          <Route path="exams" element={<ExamsList />} />
          <Route path="add-exam" element={<AddExam />} />
          <Route path="edit-exam/:examId" element={<EditExam />} />
          <Route path="view-question/:questionId" element={<QuestionView />} />
          <Route path="edit-question/:questionId" element={<EditQuestion />} />


          <Route path="teachers" element={<TeachersList />} />
          <Route path="add-teacher" element={<AddTeacher />} />
          <Route path="edit-teacher/:teacherId" element={<EditTeacher />} />

          <Route path="teacher-exams" element={<TeacherExamsList />} />
          <Route path="add-teacher-exam" element={<AddTeacherExam />} />
          <Route path="edit-teacher-exam/:examId" element={<EditTeacherExam />} />
          <Route path="exam-questions/:examId" element={<QuestionsList />} />
          <Route path="exam-students/:examId" element={<ExamStudentsList />} />
          <Route path="exam-stats/:examId" element={<ExamStats />} />

          <Route path="my-students" element={<OverallStatsPage />} />

          

          <Route path="reports" element={<ReportsList />} />
          <Route path="notifications" element={<PushNotification />} />

          <Route path="questions" element={<AllQuestionsList />} />

          
          <Route path="*" element={<PageNotFound />} />

        </Routes>
    )

}