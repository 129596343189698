import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useCrud from "../../hooks/useCrud";
import { useState, useEffect } from "react";
import { getQuestion } from "../../api/exams";

export default function QuestionView() {
  const { questionId } = useParams();
  const [question, setQuestion] = useState(null);
  const { loading, error, response, setData } = useCrud(getQuestion, (res) => {
    setQuestion(res.data);
  });
  useEffect(() => {
    setData({ id: questionId });
  }, []);

  const navigate = useNavigate();
  return (
    <>
      {question && (
        <>
          <div>
            <Box sx={{ gap: "0.5rem", display: "flex", marginBottom: "1rem" }}>
              <Chip label={question.chapter.name} color="primary" />
              <Chip label={question.section.title} color="primary" />
            </Box>
            <Typography sx={{ fontSize: "24px" }}>{question.title}</Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {question.img_url && (
                <img
                  src={question.img_url}
                  style={{
                    height: "300px",
                    marginBottom: "20px",
                    marginTop: "10px",
                  }}
                  alt={question.title}
                />
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
              {question.answers.map((a, x) => {
                return (
                  <Chip
                    label={a.title}
                    key={a.title}
                    color={a.isCorrect ? "success" : "default"}
                  />
                );
              })}
            </Box>
            <Button color="info" variant="contained" onClick={() => {navigate("/edit-question/"+question['id'])}}>  
                    Edit
                </Button>
          </div>
        </>
      )}
    </>
  );
}
