import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import StatsGraph from './StatsGraph';
import { getAllStudentsGraph, getStudentGraph } from '../../api/graphs';
import ResponseHandler from '../../shared-components/ResponseHandler';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 20,
  borderRadius: 3,
  width: "95%",
  p: 3,
};

export default function OverallStatsModal({open, handleClose}){

    return(
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Over All Stats
            </Typography>
            <StatsGraph width={"100%"} apiFunction={() => {
                    return getAllStudentsGraph()
                }} />
          </Box>
        </Fade>
      </Modal>
    )
}