import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import { editBoard, getBoard } from "../../api/boards";
import { useParams } from "react-router";
import { useEffect } from "react";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";


export default function EditBoard(){

    const navigate = useNavigate();


    const {boardId} = useParams()

    const {loading,error,success,setData} = useCrud(editBoard,() => {
        navigate("/boards")
    })

    const {response: boardResponse,loading: boardLoading,error: boardError,setData: setBoardId} = useCrud(getBoard,() => {})

    useEffect(() => {
        setBoardId({id: boardId})
    },[])

    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = data => {
        setData({data: data, id: boardId})
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Edit Board</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                { boardResponse &&
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                        size="small"
                        defaultValue={boardResponse.data.name}
                            sx={{width: "100%"}}
                            label="Name"
                            {...register("name", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.name?.type === 'required' && "Name is required"}</div>
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Update" loading={loading} />
                    </Grid>

                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Updated Successfully." />
                    </Grid>

                </Grid>
                }

                <ResponseHandler error={boardError} loading={boardLoading} />

            </Box>

        </div>
    )

}