import { Box, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import { useParams } from "react-router";
import { useEffect } from "react";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { getLevel, editLevel } from "../../api/levels";
import {useState} from "react"
import { getBoards } from "../../api/boards";
import SearchSelectBox from "../../shared-components/SearchSelectBox";


export default function EditLevel(){

    const navigate = useNavigate();


    const {levelId} = useParams()

    const {loading,error,success,setData} = useCrud(editLevel,() => {
        navigate("/levels")
    })

    const [board, setBoard] = useState(null)

    const {response: levelResponse,loading: levelLoading,error: levelError,setData: setLevelId} = useCrud(getLevel,(res) => {
        setBoard(res.data.board)
    })
    

    useEffect(() => {
        setLevelId({id: levelId})
    },[])

    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = data => {
        setData({data: {...data, board_id: board.id}, id: levelId})
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Edit Level</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                { levelResponse &&
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                        size="small"
                        defaultValue={levelResponse.data.name}
                            sx={{width: "100%"}}
                            label="Name"
                            {...register("name", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.name?.type === 'required' && "Name is required"}</div>
                    </Grid>
                    <Grid item xs={6}>
                        <SearchSelectBox 
                            multiple={false}
                            api={getBoards}
                            label="Board"
                            errors={errors}
                            register={register}
                            handleChange={(event, newValue) => {
                                setBoard(newValue)
                            }}
                            value={board}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Update" loading={loading} />
                    </Grid>

                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Updated Successfully." />
                    </Grid>

                </Grid>
                }

                <ResponseHandler error={levelError} loading={levelLoading} />

            </Box>

        </div>
    )

}