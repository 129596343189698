import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import { useDispatch } from "react-redux";
import { useNavigate  } from "react-router-dom";
import { authActions } from "../../store/auth";
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled, alpha } from '@mui/material/styles';





export default function Header({sideOpen, handleDrawerOpen}) {

  let auth = useDispatch();
  let router = useNavigate()

  function handle_logout(){
      auth(authActions.logoutHandler())
      router("/login");
  }

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }
 
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
   
    <IconButton
      ref={anchorRef}
      aria-controls={open ? 'composition-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
      sx={{color: "white", padding: "0px", marginLeft: "15px"}}
    >
      <AccountCircle sx={{fontSize: "40px"}} />
    </IconButton>
    <Popper
    sx={{minWidth: "200px", marginTop: "10px !important"}}
  open={open}
  anchorEl={anchorRef.current}
  role={undefined}
  placement="bottom-end"
  transition
  disablePortal
>
  {({ TransitionProps, placement }) => (
    <Grow
      {...TransitionProps}
      style={{
        transformOrigin:
          placement === 'bottom-start' ? 'left top' : 'left bottom',
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
          >
            <MenuItem onClick={handle_logout}>Logout</MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Grow>
  )}
</Popper>
  </Box>
 
  );
}
