import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import { useForm } from "react-hook-form";
  import { useNavigate, useParams } from "react-router-dom";
  import useCrud from "../../hooks/useCrud";
  import { useState, useEffect } from "react";
import { getQuestion } from "../../api/exams";
import SearchSelectBox from "../../shared-components/SearchSelectBox";
import ChooseImage from "../../shared-components/ChooseImage";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { getChapterTopics, getChapters } from "../../api/chapters";
import {getCurriculums } from "../../api/curriculum";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { editQuestion } from "../../api/questions";

    /** defficulty */

    const difficulties = [
        'easy',
        'medium',
        'hard'
    ]


export default function EditQuestion({}){

    const navigate = useNavigate()
    const { questionId } = useParams();

    //// get question
    const [question,setQuestion] = useState(null)

    const {
      loading,
      error,
      response,
      setData,
    } = useCrud(getQuestion, (res) => {

        setQuestion(res.data)

        setValue("image", res.data.img_url)

        setValue('curriculum', res.data.curriculum)
        setValue('chapter',res.data.chapter)
        setValue('topic',res.data.section)

        setValue("title", res.data.title)

        register("is_removed")
        setValue("is_removed", res.data.is_removed)
        
        register("difficulty", {required: true})
        setValue("difficulty", res.data.difficulty)

        register("answers", {required: true})
        setValue("answers", res.data.answers)


    });

    useEffect(() => {
        setData({ id: questionId });
    }, []);


    //// update

    const { loading: updateLoading, error: updateError, success: updateSuccess, setData: setUpdate } = useCrud(editQuestion, () => {

    });

    const {
        register,
        watch,
        setValue,
        formState: { errors, dirtyFields, touchedFields },
        handleSubmit,
      } = useForm();
    
      const onSubmit = (formData) => {
        const data = new FormData();
     
          data.append("title", formData.title);
          if (formData.image && typeof formData.image != "string") {
            data.append("image", formData.image);
          }
          if( formData.topic ){
            data.append("section_id", formData.topic.id);
          }
          data.append("difficulty", formData.difficulty);
          data.append("is_removed", formData.is_removed ? 1 : 0);
          formData.answers.map((a, ind) => {
            data.append(
              "answers[" + ind + "][title]",
              a.title
            );
            if (a.img && typeof a.img != "string") {
              data.append(
                "answers[" + ind + "][image]",
                a.img
              );
            }
            data.append(
              "answers[" + ind + "][isCorrect]",
              a.isCorrect
            );
          });
        
        setUpdate({ data: data, id: questionId });
      }

     
      return(
        <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
            {question && <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField size="small" 
                        {...register("title", { required: true })}
                        sx={{width: "100%"}}
                        placeholder="Question"
                    />
                    <div className="validationError" style={{width: "100%"}}>
                        {
                        errors['title']?.type === "required" && 
                        "Question Text is required"
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <ButtonGroup disableElevation color='info' size="medium" style={{width: "100%"}}>
                        <Button onClick={() => {
                            setValue("is_removed",false)
                        }} variant={!watch("is_removed") ? "contained" : "outlined"} style={{width: "100%"}}>Exist</Button>
                        <Button onClick={() => {
                            setValue("is_removed",true)
                        }} variant={watch("is_removed") ? "contained" : "outlined"} style={{width: "100%"}}>Removed</Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={8}>
                    <ButtonGroup disableElevation size="medium" style={{width: "100%"}}>
                        {difficulties.map(d => {
                            return <Button onClick={() => {
                                setValue("difficulty",d) 
                            }} variant={watch("difficulty") === d ? "contained" : "outlined"} style={{width: "100%"}} key={d}>{d}</Button>
                        })}
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SearchSelectBox
                                multiple={false}
                                api={getCurriculums}
                                register={register}
                                value={watch("curriculum")}
                                handleChange={(event, newValue) => {
                                    setValue("curriculum", newValue)
                                    setValue("chapter", null)
                                }}
                                label="Curriculum"
                                name="curriculum"
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {watch("curriculum") && <SearchSelectBox 
                                multiple={false}
                                register={register}
                                errors={errors}
                                api={getChapters}
                                payload={watch("curriculum").id}
                                value={watch("chapter")}
                                handleChange={(event, newValue) => {
                                    setValue("chapter", newValue)
                                    setValue("topic", null) 
                                }}
                                label="Chapter"
                                name={"chapter"}
                            />}
                        </Grid>
                        <Grid item xs={4}>
                            {watch("chapter") &&<SearchSelectBox 
                                multiple={false}
                                api={getChapterTopics}
                                register={register}
                                errors={errors}
                                payload={watch("chapter").id}
                                value={watch("topic")}
                                handleChange={(event, newValue) => {
                                    setValue("topic", newValue) 
                                }}
                                label="Topic"
                                name="topic"
                                searchLabel="title"
                            />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ChooseImage 
                        {...register('image', { validate: 
                            () => {
                                return watch("image") ? true : false
                            } 
                        })}
                        image={watch("image") ? (typeof watch("image") === 'string' ? watch("image") : URL.createObjectURL(watch("image"))) : null} 
                        handleSelectImage={(v) => {
                            setValue("image", v)
                        }} 
                    />
                    <div className="validationError">{errors['image']?.type === 'validate' && !question.img && "Question Image is required"}</div>
                </Grid>
                <Grid item xs={12}>
                    <h5 style={{margin: 0}}>Correct Answer</h5>
                </Grid>
                {
                    watch("answers").map((a, i) => {
                        return <Grid item xs={3} key={"a"+i}>
                            <div style={{backgroundColor: (a.isCorrect ? "rgb(237, 247, 237)" : ""), padding: "10px", borderRadius: 8, textAlign: "center"}} >
                                <label style={{display: "block",}}>{a.title}</label>
                                <IconButton sx={{fontSize: "20px"}} variant="contained" color={a.isCorrect ? "success" : "primary"} onClick={() => {
                                    if(!a.isCorrect){
                                        setValue("answers", watch("answers").map((an => {
                                            if(an.id == a.id){
                                                return {...an, isCorrect: true}
                                            }else{
                                                return {...an, isCorrect: false}
                                            }
                                        })))
                                    }
                                }}>
                                    
                                    <CheckRoundedIcon />
                                </IconButton>
                            </div>
                        </Grid>
                    })
                }

                <Grid item xs={12}>
                    <ResponseHandler
                        error={updateError}
                        success={updateSuccess}
                        successMsg="Updated Successfully."
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                    <FormButton txt="Update" loading={updateLoading} />
                </Grid>
            </Grid>}
        </Box>
    )
}