import { useEffect } from "react"
import { useState } from "react"
import { getTopWrongQuestions } from "../../api/graphs"
import { useParams } from "react-router"
import ResponseHandler from "../../shared-components/ResponseHandler"
import { Button, Card } from "@mui/material"
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';


export default function ExamStats(){

    const {examId} = useParams()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        setLoading(true)
        getTopWrongQuestions(examId).then((res) => {
            setLoading(false)
            setData(res.data)
        })
    }, [])

 

    return(
        <div className="page">
            <div className="header" style={{display: 'flex', justifyContent: "space-between"}}>
                <h1>Exam Stats</h1>
                <PDFDownloadLink  document={<PDF_File data={data} />} fileName="exam-stats.pdf">
                 
                    <Button color='primary'variant="contained">
                        Export PDF
                    </Button>
                </PDFDownloadLink>
            </div>

            <Card variant="outlined" sx={{marginBottom: "15px"}}>
                <CardContent sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography sx={{ fontSize: 18 }}>
                        <span style={{fontWeight: "bold", fontSize: 18, color: "black", whiteSpace: "nowrap", marginRight: "15px"}}>#</span>
                        Question
                    </Typography>
                    <Typography sx={{ fontSize: 18 }}>
                        Wrong Answers (%)
                    </Typography>
                </CardContent>
                <CardActions sx={{padding: 0}}>
                </CardActions>
            </Card>

            <ResponseHandler empty={data.length===0 && !loading} emptyMsg="No Questions Yet!" loading={loading} />

            {data.map((q, i) => {

                return <Card key={q.id} variant="outlined" sx={{marginBottom: "15px"}}>
                    <CardContent sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography sx={{ fontSize: 15, width: "80%", display: "flex", alignItems: "flex-start", paddingRight: 15, marginBottom: 0 }} color="text.secondary" gutterBottom>
                            <span style={{fontWeight: "bold", fontSize: 18, color: "black", whiteSpace: "nowrap", marginRight: "15px"}}>{i+1} .</span>
                            <img src={q.question_img} style={{width: "100px", display: "block", marginRight: "10px"}} />
                            {q.question}
                        </Typography>
                        <Typography sx={{ fontSize: 20, textAlign: "right", width: "20%" }}>
                            {Math.round(q.wrongPercentage)}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{padding: 0}}>
                    </CardActions>
                </Card>

            })}

        </div>
    )
}

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
    },
    section: {
        margin: 25,
        padding: 25,
        border: '2px solid black',
    }
  });

function PDF_File({data}){
    return <Document>
    <Page size="A4" style={styles.page} wrap={false}>
      <View style={styles.section}>
        <Text style={{marginBottom: 20}}>Exam Stats</Text>
        {data.map((q, i) => {

            return <View key={q.id} style={{marginBottom: "15px", borderBottom: '1px solid gray'}}>
                <View style={{display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "flex-start"}}>
                    <View style={{ fontSize: 15, width: "80%", flexDirection: "row", alignItems: "flex-start", paddingRight: 15, marginBottom: 0 }} >
                        <Text style={{fontWeight: "bold", fontSize: 17, color: "black", whiteSpace: "nowrap", marginRight: "15px"}}>{i+1} .</Text>
                        <Image src={{uri: q.question_img}} style={{width: 300, }} />
                    </View>
                    <View>
                        <View style={{flexDirection: "row", alignItems: "flex-end", marginBottom: 10}}>
                            <Text style={{ fontSize: 13, color: "gray", marginRight: 5 }}>
                                Wrong answers:
                            </Text>
                            <Text style={{ fontSize: 18 }}>
                                {q.wrongAnswers}
                            </Text>
                        </View>
                        <View style={{}}>
                            <Text style={{ fontSize: 13, color: "gray", marginBottom: 5 }}>
                                Wrong percentage:
                            </Text>
                            <Text style={{ fontSize: 18 }}>
                                {Math.round(q.wrongPercentage)} %
                            </Text>
                        </View>
                    </View>
                    
                    
                </View>

            </View>

        })}
      </View>
    </Page>
  </Document>
}