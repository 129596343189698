import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Button, ButtonGroup, Grid, TextField, } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ChooseImage from '../../shared-components/ChooseImage';
import SearchSelectBox from '../../shared-components/SearchSelectBox';
import { getChapters, getChapterTopics } from '../../api/chapters';

    /** defficulty */

    const difficulties = [
        'easy',
        'medium',
        'hard'
    ]

    
export default function QuestionAccordion(props){

    const [expanded, setExpanded] = useState(true)

    function handleFileSelected(e,i){
        const files = Array.from(e.target.files)
        props.handleChangeAnswerImg(files[0],i)
    }

    return (

        <Accordion expanded={expanded}>
            <AccordionSummary
                sx={{backgroundColor: "white !important"}}
                expandIcon={<ExpandMoreIcon onClick={() => {setExpanded(!expanded)}} />}
            >
                <div style={{width: "100%",display: "flex", flexWrap: "wrap"}}>

                    <div style={{width: "100%", display: "flex"}}>
                        <IconButton color="error" variant="contained" sx={{marginRight: "10px"}} onClick={() => {props.removeQuestion(props.index)}}>
                            <DeleteIcon />
                        </IconButton>
                        
                        <TextField onFocus={() => {setExpanded(true)}} size="small" 
                            {...props.register("questionText"+props.index, { required: true })}
                            sx={{width: "100%", marginRight: "16px"}}
                            placeholder="Question"
                            value={props.question.title}
                            onChange={(e) => {
                                props.handleChangeQuestion(e.target.value)
                            }}
                        />

                        <TextField
                            size="small"
                            type="hidden"
                            {...props.register("questionAnswerCorrect-"+props.index, 
                                { validate: 
                                    () => {
                                        return props.answers.filter(an => an.isCorrect).length > 0 ? true : false
                                    } 
                                }
                            )}
                            
                            label="Correct"
                        /> 

                    </div>
                    
                        <div className="validationError" style={{width: "100%", paddingLeft: "50px"}}>
                            {
                            props.errors['questionText'+props.index]?.type === "required" && 
                            "Question Text is required"
                            }
                        </div>
                       
                </div>
               
            </AccordionSummary>
            <AccordionDetails sx={{paddingTop: "0px"}}>
                <Grid container spacing={2}>

                    <Grid item xs={4}>
                        <ButtonGroup disableElevation color='info' size="medium" style={{width: "100%"}}>
                            <Button onClick={() => {props.handleMarkRemoved(false)}} variant={!props.question.is_removed ? "contained" : "outlined"} style={{width: "100%"}}>Exist</Button>
                            <Button onClick={() => {props.handleMarkRemoved(true)}} variant={props.question.is_removed ? "contained" : "outlined"} style={{width: "100%"}}>Removed</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={8}>
                        <ButtonGroup disableElevation size="medium" style={{width: "100%"}}>
                            {difficulties.map(d => {
                                return <Button onClick={() => {props.handleChangeDifficulty(d)}} variant={props.question.difficulty === d ? "contained" : "outlined"} style={{width: "100%"}} key={d}>{d}</Button>
                            })}
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <SearchSelectBox 
                                    multiple={false}
                                    register={props.register}
                                    errors={props.errors}
                                    api={getChapters}
                                    payload={props.curriculumId}
                                    value={props.question.chapter}
                                    handleChange={(event, newValue) => {
                                        props.handleChangeQuestionChapter(newValue);
                                    }}
                                    label="Chapter"
                                    disabled={!props.curriculumId}
                                    name={"questionChapter-"+props.index}
                                />
                            </Grid>
                            <Grid item xs={6}>
                            {props.question.chapter && <SearchSelectBox 
                                multiple={false}
                                api={getChapterTopics}
                                payload={props.question.chapter.id}
                                value={props.question.topic}
                                handleChange={(event, newValue) => {
                                    props.handleChangeQuestionTopic(newValue);
                                }}
                                label="Topic"
                                noValidate={true}
                                searchLabel="title"
                            />}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ChooseImage 
                            {...props.register('questionImage'+props.index, { validate: 
                                () => {
                                    console.log('questionImage'+props.index,props.question.img)
                                    return props.question.img ? true : false
                                } 
                            })}
                            image={props.question.img ? (typeof props.question.img === 'string' ? props.question.img : URL.createObjectURL(props.question.img)) : null} 
                            handleSelectImage={props.handleChangeQuestionImage} 
                        />
                        <div className="validationError">{props.errors['questionImage'+props.index]?.type === 'validate' && !props.question.img && "Question Image is required"}</div>
                    </Grid>
                    <Grid item xs={12}>
                        <h5 style={{margin: 0}}>Correct Answer</h5>
                        <div className="validationError">
                           
                            {
                            props.errors["questionAnswerCorrect-"+props.index]?.type === "validate" && 
                            "Question Must Have Correct Answer"
                            }
                        </div>
                    </Grid>
                    { 
                        props.answers.map((a, i) => {
                            return <Grid item xs={3} key={"a"+i+props.index}>
                                {/*<IconButton style={{marginRight: "10px"}} color="primary" aria-label="upload picture" component="label">
                                    <input onChange={(e) => {handleFileSelected(e,i)}} hidden accept="image/*" type="file" />
                                    <PhotoCamera />
                                </IconButton>*/}
                                {/* a.img &&
                                    <img src={URL.createObjectURL(a.img)} style={{width: "40px",height: "40px", borderRadius: "10px", marginRight: "15px"}} />
                                */}
                                {/*<div style={{width: "100%"}}>
                                    <TextField
                                        size="small"
                                        disabled
                                        value={a.title}
                                        onChange={(e) => {
                                            props.handleChangeAnswerText(e.target.value,i)
                                        }}
                                        sx={{width: "100%", marginRight: "17px"}}
                                        label="Answer"
                                    /> 
                                    </div>*/}
                                
                                <div style={{backgroundColor: (a.isCorrect ? "rgb(237, 247, 237)" : ""), padding: "10px", borderRadius: 8, textAlign: "center"}} >
                                    <label style={{display: "block",}}>{a.title}</label>
                                    <IconButton sx={{fontSize: "20px"}} variant="contained" color={a.isCorrect ? "success" : "primary"} onClick={() => {
                                        if(!a.isCorrect){
                                            props.handleChooseCorrectAnswer(i)
                                        }
                                    }}>
                                        
                                        <CheckRoundedIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                        })
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>
    )

}