import { myAxios } from "./axios"

export function getTeachers(config){
    const headers = {
        params: config
    }
    return myAxios.get('/teachers',headers)
}


export function getTeacher(data){
    return myAxios.get('/teacher/'+data.id+'/show')
}

export function addTeacher(data){
    return myAxios.post('/create/teacher',data)
}

export function editTeacher(data){
    return myAxios.put('/teacher/'+data.id+'/update',data.data)
}

export function deleteTeacher(data){
    return myAxios.delete('/teacher/'+data.id+'/delete')
}

export function getTeacherQuestions(config){
    const headers = {
        params: {
            page: config.page,
            sections_ids: config.q
        }
    }
    return myAxios.get('/teacherQuestions',headers)
}

export function getTeacherChapters(){
    /*const headers = {
        params: {
            page: config.page,
            q: config.q ? config.q.q: null
        }
    }*/
    return myAxios.get('/teacherChapters')
}

export function allowExamRetake({examId, studId}){
    /*const headers = {
        params: {
            page: config.page,
            q: config.q ? config.q.q: null
        }
    }*/

    return myAxios.post(`/exams/${examId}/students/${studId}/open-retake`)
}