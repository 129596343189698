

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import useCrud from '../../hooks/useCrud';
import { allowExamRetake } from '../../api/teachers';
import ResponseHandler from '../../shared-components/ResponseHandler';

export default function RetakeModal({open, setOpen, stud, examId, refetchTable}) {

  const handleClose = () => {
    setOpen(false);
  };

  const { loading, error, success, setData } = useCrud(allowExamRetake, () => {
    setOpen(false)
    refetchTable()
  });

  const handleConfirm = () => {
    setData({
        examId,
        studId: stud.id
    })
  }

  return (
    <React.Fragment>
      
      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        sx={{textAlign: "center"}}
      >
        <DialogTitle sx={{paddingTop: "35px"}}>
          <ErrorOutlineOutlinedIcon sx={{fontSize: "100px", color:"orange",display: "block",margin: "0px auto 15px auto !important"}} />
          {"Are You Sure ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you relly want to allow {stud?.name} to retake the exam 
          </DialogContentText>

          <ResponseHandler error={error} />
          
        </DialogContent>
        <DialogActions sx={{justifyContent: "center",paddingBottom: "35px"}}>
          <Button loading={loading ? true : false} onClick={handleClose}>Cancel</Button>
          <Button color="error" onClick={() => {
            handleConfirm()
            }}>Confirm</Button>
        </DialogActions>
      </Dialog>
      </React.Fragment>
  );
}
