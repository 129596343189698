
  import { useNavigate, useParams } from "react-router-dom";
  import useCrud from "../../hooks/useCrud";
  import { editTeacherExam, getTeacherExam } from "../../api/exams";
  import { useState, useEffect } from "react";
  import Accordion from '@mui/material/Accordion';
  import AccordionSummary from '@mui/material/AccordionSummary';
  import AccordionDetails from '@mui/material/AccordionDetails';
  import Typography from '@mui/material/Typography';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import QuestionsTable from "./QuestionsTable";
  import { Badge, Button, Chip, Grid } from "@mui/material";
import { getTeacherChapters } from "../../api/teachers";

  export default function QuestionsList() {
    const navigate = useNavigate();

    const [edited, setEdited] = useState(false)

    // get exam
  
    const { examId } = useParams();
    const {
      loading: examLoading,
      error: examError,
      response: examData,
      setData: setExamId,
    } = useCrud(getTeacherExam, (res) => {
      setQuestions(res.data.questions_for_exam);
    });
    
    useEffect(() => {
      setExamId({ id: examId });
    }, []);
  
    // submit
    const { loading, error, success, setData } = useCrud(editTeacherExam, () => {
      navigate("/teacher-exams");
    });
    
    const [questions, setQuestions] = useState([])
  
    function handleChangeQuestion(qu){
      if( questions.map(q => q.id).includes(qu.id) ){
        setQuestions(questions.filter(q => q.id != qu.id))
      }else{
        setQuestions([...questions, qu])
      }
      setEdited(true)
    }

    function handleUpdateQuestions(){
      setData({questions_ids: questions.map(q => q.id),id: examId})
    }

    // chapters
    const [chapters, setChapters] = useState([])
    useEffect(() => {
        getTeacherChapters().then((res) => {
            setChapters(res.data)
        },(err) => {
            console.log(err)
        })
    },[])

    const [filteredSections, setFilteredSections] = useState([])

    function handleChangeSection(sectionId){
        if( filteredSections.includes(sectionId) ){
            setFilteredSections(filteredSections.filter(s => s != sectionId))
        }else{
            setFilteredSections([...filteredSections, sectionId])
        }
    }

    // answers chars const

    const ANSWERS = [
      "a","b","c", "d"
    ]

    return (
      <div className="page">
        <div className="header">
          <h1 style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
            <span>Exam Questions</span>
            {edited && <Button variant="contained" color={"primary"} onClick={handleUpdateQuestions}>
                Update
            </Button>}
          </h1>
        </div>
        <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography>Selected Questions ({questions.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              { questions.map((q, i) => {
                return <div key={q.id}>
                  <Typography sx={{display: "block", marginBottom: '10px'}}>{i+1 +" - " +q.title} </Typography>
                  { q.answers.find((a) => a.isCorrect) &&  <Chip  sx={{ fontSize: '14px'}} label={'Correct answer: '+q.answers.find((a) => a.isCorrect).title.toUpperCase()} color="success"></Chip>}
                  {q.img_url && <img src={q.img_url} style={{height: "300px", marginBottom: "10px", marginTop: "15px", display: "block"}} />}
              
                </div>
              })}
            </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={8}>
            <QuestionsTable questions={questions} filteredSections={filteredSections} handleChangeQuestion={handleChangeQuestion} />
          </Grid>
          <Grid item xs={4}>
          <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography>Chapters</Typography>
        </AccordionSummary>
        <AccordionDetails>
                { chapters.map(c => {
                    return <div key={c.id}>
                        <Typography>{c.name}</Typography>
                        <div style={{marginLeft: "20px"}}>
                            { c.sections.map(s => {
                                return <div key={s.id} style={{display: "flex", alignItems: "baseline"}}>
                                    <input type={"checkbox"} style={{marginRight: "10px"}} value={s.id} onChange={() => {handleChangeSection(s.id)}} checked={filteredSections.includes(s.id)} />
                                    <label>{s.title}</label>
                                    </div>
                            })

                            }
                        </div>
                    </div>
                })

                }
        </AccordionDetails>
      </Accordion>
          </Grid>
        </Grid>
        
      </div>
    );
  }
  