import { myAxios } from "./axios";


export function getGrades(config){
    const headers = {
        params: config
    }
    return myAxios.get('/grades',headers)
}

export function getGrade(data){
    return myAxios.get('/grade/'+data.id+'/show')
}

export function addGrade(data){
    return myAxios.post('/grade/create',data)
}

export function editGrade(data){
    return myAxios.put('/grade/'+data.id+'/update',data.data)
}

export function deleteGrade(data){
    return myAxios.delete('/grade/'+data.id+'/delete')
}