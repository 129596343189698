import { myAxios } from "./axios";


export function getChapters(config){

    const headers = {
        params: {
            page: config.page,
            q: config.q && config.q.q ? config.q.q : null,
            curriculum_id: config.q && config.q.id ? config.q.id : null
        }
    }
    return myAxios.get('/chapters',headers)
}

export function getChapterTopics(config){
    const headers = {
        params: {
            chapter_id: config.q.id,
            page: config.page,
            q: config.q.q
        }
    }
    return myAxios.get('/sections',headers)
}