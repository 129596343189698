import { useState } from "react"
import { useEffect } from "react"
import Chart from "react-apexcharts";
import ResponseHandler from "../../shared-components/ResponseHandler";


export default function StatsGraph({apiFunction, width}){

    const [options, setOptions] = useState(null)
    const [series, setSeries] = useState(null)

    const [loading, setLoading] = useState(false)
    

    useEffect(() => {
        setLoading(true)
        apiFunction().then(res => {
            setOptions({
                chart: {
                  id: "basic-bar"
                },
                plotOptions: {
                  bar: {
                      horizontal: false,
                      borderRadius: 4,
                      
                      columnWidth: '10%',
                      barHeight: '70%',
                  }
                },
                xaxis: {
                  categories: res.data.map(d => d.chapter),
                  labels: {
                    trim: true,
                    rotate: 0,
                    hideOverlappingLabels: false,
                    style: {
                        colors: [],
                        fontSize: '10px',
                        //fontFamily: 'Helvetica, Arial, sans-serif',
                        //fontWeight: 400,
                        //cssClass: 'apexcharts-xaxis-label',
                    },
                  }
                },
                yaxis: {
                  min: 0,
                  max: 100,
                }
            })
            setSeries([
                {
                  name: "Correct answers (%)",
                  data: res.data.map(d => Math.round(d.percentage))
                }
            ])
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return(
        <div className="">
            <ResponseHandler loading={loading} />

            {options && series && <Chart
              options={options}
              series={series}
              type="bar"
              width={width ? width : "500"}
              height={"400"}
            />}
        </div>
    )
}