import * as React from "react";
import { deleteReport, getReports } from "../../api/exams";
import ListTable from "../../shared-components/ListTable";

const columns = [
  {
    id: "id",
    label: "Id",

    href: (id) => {
      return "/view-question/" + id;
    },
    width: 50,
    align: "center"
  }
,
  { id: 'reason', label: 'Reason', width: 50 },
  { id: 'question', subId: 'title', label: 'Question',width: 50 },
  { id: 'subject', subId: 'name', label: 'Subject',width: 50 },

  { 
    id: 'date', 
    label: 'Date',
    getter: (data) => data?.exam ? `${data?.exam?.year} (${data?.exam?.month})` : '',
    width: 50 
  },
  {
    id: "chapter",
    label: "Chapter",
    getter: (data) => `${data?.question?.chapter?.name} \n (${data?.question?.section?.title})`,
    width: 50
  },
  {
    id: "user",
    label: "Submitted By",
    getter: (data) => `${data?.user?.name}  (${data?.user?.email})`,
    width: 50
  },
  { id: 'action',  label: 'Action',
  width: 50 },

];

export default function ReportsList() {
  return (
    <div className="page">
      <div className="header">
        <h1>Reports</h1>
      </div>

      <ListTable
        api={getReports}
        hasEdit={false}
        columns={columns}
        type="report"
        hasDelete={true}
        deleteApi={deleteReport}
      />
    </div>
  );
}
