import { myAxios } from "./axios";


export function getLevels(config){
    const headers = {
        params: config
    }
    return myAxios.get('/levels',headers)
}


export function getLevel(data){
    return myAxios.get('/level/'+data.id+'/show')
}

export function addLevel(data){
    return myAxios.post('/level/create',data)
}

export function editLevel(data){
    return myAxios.put('/level/'+data.id+'/update',data.data)
}

export function deleteLevel(data){
    return myAxios.delete('/level/'+data.id+'/delete')
}