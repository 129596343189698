import { myAxios } from "./axios";


export function getSubjects(config){
    const headers = {
        params: config
    }
    return myAxios.get('/subjects',headers)
}

export function getSubject(data){
    return myAxios.get('/subject/'+data.id+'/show')
}

export function addSubject(data){
    return myAxios.post('/subject/create',data)
}

export function editSubject(data){
    return myAxios.post('/subject/'+data.id+'/update',data.data)
}

export function deleteSubject(data){
    return myAxios.delete('/subject/'+data.id+'/delete')
}


