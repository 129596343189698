
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import { useState, useRef } from 'react';
import { Button, ButtonGroup, TextField, } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';


export function LaneHeader({  
    updateTitle,
    canAddLanes,
    onDelete,
    onDoubleClick,
    editLaneTitle,
    label,
    title,
    id,
    handleAddTopic,
    titleStyle,
    laneDraggable
}){

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };
  
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    return(
        
        <div style={{display: "flex", alignItems: "center"}}>
            <div style={{width: "90%"}}>
                
                <TextField
                    onChange={(e) => {updateTitle(e.target.value)}}
                    value={title}
                    size="small"
                    placeholder='Name'
                    sx={{width: "100%"}}
                /> 
                
            </div>
            
            <MoreVertIcon 
                ref={anchorRef}
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                sx={{paddingRight: "0px",transform: "translateX(10px)", cursor: "pointer"}} 
            />

            <Popper
                sx={{ position: "relative", zIndex: "9999"}}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="right-end"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                            >
                                <MenuItem onClick={() => {handleAddTopic(id)}} sx={{fontSize: "15px"}}>
                                    <AddIcon sx={{marginRight: "10px", fontSize: "17px"}} />
                                    Add Topic
                                </MenuItem>
                                <MenuItem onClick={onDelete} sx={{fontSize: "15px"}}>
                                    <DeleteIcon sx={{marginRight: "10px", fontSize: "17px"}} />
                                    Delete Chapter
                                </MenuItem>
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </div>   
    )
}


export function TopicCard({
    showDeleteButton,
    style,
    onClick,
    onDelete,
    onChange,
    className,
    id,
    title,
    cardDraggable,
    editable,
  }){

    return (
        <div style={{
            backgroundColor: "white",
            padding: "15px",
            borderRadius: "5px"
        }}>
                <label style={{fontSize: "15px", marginBottom: "5px", display: "flex", justifyContent: "space-between"}}>
                    Topic
                    <CloseIcon sx={{fontSize: "15px", cursor: "pointer"}} onClick={onDelete} />
                </label>
                <TextField
                    onChange={(e) => {
                        onChange({id, title: e.target.value})
                    }}
                    value={title}
                    size="small"
                    placeholder='Name...'
                    sx={{width: "100%"}}
                /> 
                
            </div>
    )

}