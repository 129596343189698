
  import Table from '@mui/material/Table';
  import TableBody from '@mui/material/TableBody';
  import TableCell from '@mui/material/TableCell';
  import TableContainer from '@mui/material/TableContainer';
  import TableHead from '@mui/material/TableHead';
  import TablePagination from '@mui/material/TablePagination';
  import TableRow from '@mui/material/TableRow';
  import usePagination from '../../hooks/usePagination';
  import { getTeacherChapters, getTeacherQuestions } from "../../api/teachers";
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ResponseHandler from '../../shared-components/ResponseHandler';
import image from '../../assets/imgs/placeholder-image.webp'
import QuestionImageModal from '../questions/QuestionImageModal';

  const columns = [
    { id: 'id', label: 'Id', minWidth: 50 },
    { id: 'img', label: 'Image', minWidth: 100 },
    { id: 'title', label: 'Title', minWidth: 100 },
    { id: 'exam', label: 'Year', getter: (r) => r.exam ? r.exam.year : '', minWidth: 100 },

  ];

export default function QuestionsTable({questions, handleChangeQuestion, filteredSections}){
    const {response: questionsResponse,error: questionsError,loading: questionsLoading, page, handleChangePage, setPayload} = usePagination(getTeacherQuestions)
    useEffect(() => {
        setPayload(filteredSections)
    },[filteredSections])

    const [open, setOpen] = useState(false);
    const [img, setImg] = useState(null)

    const handleClose = () => {
      setOpen(false);
    };
  

    return(
        <>
        { questionsResponse && !questionsLoading && questionsResponse.data.data.length > 0 &&
            <>
           
                    <ResponseHandler loading={questionsLoading} error={questionsError} />
                <TableContainer sx={{border: "1px solid #e0e0e0"}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { 
                questionsResponse.data.data.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell>
                          <input checked={questions.map(q => q.id).includes(row.id)} type={"checkbox"} onChange={() => {handleChangeQuestion(row)}} />
                        </TableCell>
                        <TableCell>
                          <img onClick={() => {
                            setOpen(true)
                            setImg(row.img_url ? row.img_url : image)
                          }} style={{width: "50px", cursor: "pointer"}} src={row.img_url ? row.img_url : image} />
                        </TableCell>
                        <TableCell>
                          {row.title}
                        </TableCell>
                        <TableCell>
                          {row.exam ? row.exam.year : '-'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={questionsResponse.data.total} /** total items */
            rowsPerPage={questionsResponse.data.per_page} /** page size */
            page={page} /** current page */
            onPageChange={handleChangePage}
            onRowsPerPageChange={() => {}}
          />
              <QuestionImageModal 
                open={open}
                handleClose={handleClose}
                img={img}
              />  
        
            </>
          }
        </>
    )
}