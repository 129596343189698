import { useEffect } from "react"
import { getAllStudentsGraph, getMyStudents } from "../../api/graphs"
import StatsGraph from "./StatsGraph"
import ListTable from "../../shared-components/ListTable"
import StudentStatsModal from './StudentStatsModal';
import { useState } from "react";
import OverallStatsModal from "./OverallStatsModal";
import { Button } from "@mui/material";

const columns = [
    { id: 'email',  label: 'Email', minWidth: 120 },
    { id: 'name',  label: 'Name', minWidth: 100 },
    { id: 'action',  label: 'Action', minWidth: 100, align: "center" },

  ];

export default function OverallStatsPage({}){

    const [choosedStud, setChoosedStud] = useState(null)

    const [overallOpen, setOverallOpen] = useState(false)


    return(
        <div className='page'>
      
            <div className='header'>
                <h1>My Students</h1>
                <Button size="large" sx={{marginBottom: "20px"}} variant="contained" onClick={() => setOverallOpen(true)}>
                    View Stats
                </Button>
            </div>

            <ListTable 
            statsBtn={
                {
                    label: "Stats",
                    func: (stud) => {
                        setChoosedStud(stud)
                    }
                }
            } api={getMyStudents} hasEdit={false} hasDelete={false} columns={columns} type="student" />

            {choosedStud && <StudentStatsModal open={choosedStud ? true : false} stud={choosedStud} handleClose={() => setChoosedStud(null)} />}
            {overallOpen && <OverallStatsModal open={overallOpen} handleClose={() => setOverallOpen(null)} />}


        </div>
    )
}