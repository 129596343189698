
import { useDispatch } from "react-redux";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate  } from "react-router-dom";
import { authActions } from "../../store/auth";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Leaderboard, LeaderboardOutlined, QuestionMark, VerifiedUser, VerifiedUserSharp } from "@mui/icons-material";
export default function Sidebar({open}){

    let router = useNavigate()

    const isDataEntery = JSON.parse(localStorage.getItem('user')).email.split("@")[1] === 'entry.com'

    return(
        <List>

            {JSON.parse(localStorage.getItem('user')).type === 'default' && !isDataEntery && 
              <>
                <ListItem onClick={() => {router('/teachers')}} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <PeopleOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Teachers'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                </ListItem>

                <ListItem onClick={() => {router('/boards')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <ContentPasteOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Boards'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={() => {router('/subjects')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <BookOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Subjects'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={() => {router('/grades')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <AddTaskOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Grades'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={() => {router('/levels')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <SchoolOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Levels'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
                
                <ListItem onClick={() => {router('/curriculums')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <CoPresentOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Curriculums'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={() => {router('/exams')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <AccessAlarmsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Exams'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={() => {router('/reports')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <ContentPasteOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Reports'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={() => {router('/notifications')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <ContentPasteOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Notifications'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </>
            }

            {JSON.parse(localStorage.getItem('user')).type === 'default' && isDataEntery && 
              <>
                <ListItem onClick={() => {router('/curriculums')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <CoPresentOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Curriculums'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>

                <ListItem onClick={() => {router('/exams')}} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <AccessAlarmsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Exams'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </>
            }

            {JSON.parse(localStorage.getItem('user')).type != 'default' && 
            <>
              <ListItem onClick={() => {router('/teacher-exams')}} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ContentPasteOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'My Exams'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              <ListItem onClick={() => {router('/my-students')}} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <PeopleOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={'My Students'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </>
            }
            
        </List>
    )

}