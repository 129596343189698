import LoadingButton from '@mui/lab/LoadingButton';


export default function FormButton(props){

    return (
        <>
           
<LoadingButton
            variant="contained"
            type="submit"
          loading={props.loading}
          size="large"
          disabled={props.loading}
        >
          {props.txt}
        </LoadingButton>
        </>
    )

}