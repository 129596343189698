import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Alert, Box, Button, ButtonGroup, IconButton, Link, Paper, Popper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import usePagination from '../hooks/usePagination';
import DeleteModal from './DeleteModal';
import useCrud from "../hooks/useCrud"
import "./ListTable.css"
import ResponseHandler from './ResponseHandler';
import { CSVLink, CSVDownload } from "react-csv";
import image from "../assets/imgs/placeholder-image.webp"
import QuestionImageModal from '../pages/questions/QuestionImageModal';
import {useState} from 'react';
import Fade from '@mui/material/Fade';
import { MoreHorizOutlined } from '@mui/icons-material';


export default function TableCompressActionCol({row, handleConfirmDelete, ...props}){

    const navigate = useNavigate()

    const [compressOpen, setCompressOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    return(
        <>
            <Button variant='contained' color='info' onClick={(e) => {
            setCompressOpen(!compressOpen) 
            setAnchorEl(e.currentTarget)
            }} >
                <MoreHorizOutlined />
            </Button>
            <Popper open={compressOpen} anchorEl={anchorEl} placement={'bottom'} transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper sx={{p: 2}}>
                    
                    
                        <Button color='info' sx={{display: "block", color: "black"}} onClick={() => {navigate("/edit-"+props.type+"/"+row['id'])}}>  
                            Edit 
                        </Button>
                    
                        <DeleteModal color="black" type={props.type} handleConfirm={() => {
                            handleConfirmDelete(row['id'])
                        }} />
                    
                    { props.additionalBtnUrl &&
                        <Button sx={{display: "block", color: "black"}} color="info" onClick={() => {navigate("/"+props.additionalBtnUrl+"/"+row['id'])}}>  
                            {props.additionalBtnUrl.replace("-", ' ')}
                        </Button>
                    }
                    { props.additionalBtnUrl2 &&
                            <Button color='info' sx={{display: "block", color: "black"}} onClick={() => {navigate("/"+props.additionalBtnUrl2+"/"+row['id'])}}>  
                                {props.additionalBtnUrl2.replace("-", ' ')}
                            </Button>
                    }
                    { props.additionalBtnUrl3 &&
                            <Button color="info" sx={{display: "block", color: "black"}} onClick={() => {navigate("/"+props.additionalBtnUrl3+"/"+row['id'])}}>  
                                {props.additionalBtnUrl3.replace("-", ' ')}
                            </Button>
                    }
                    </Paper>
                </Fade>
                )}
            </Popper>
        </>
    )
}