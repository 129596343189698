import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useCrud from "../../hooks/useCrud";
import { addExam } from "../../api/exams";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { useEffect, useState } from "react";
import SearchSelectBox from "../../shared-components/SearchSelectBox";
import { getCurriculums } from "../../api/curriculum";
import AddIcon from "@mui/icons-material/Add";
import QuestionAccordion from "./QuestionAccordion";


export default function AddExam() {
  const navigate = useNavigate();

  const { loading, error, success, setData } = useCrud(addExam, () => {
    navigate("/exams");
  });

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (formData) => {
    const data = new FormData();
    data.append("name", formData.name);
    data.append("year", formData.year);
    data.append("month", formData.mounth);
    data.append("curriculum_id", curriculum.id);
    questions.map((q, i) => {
      data.append("questions[" + i + "][title]", q.title);
      data.append("questions[" + i + "][image]", q.img);
      if( q.topic ){
        data.append("questions[" + i + "][section_id]", q.topic.id);

      }
      data.append("questions[" + i + "][is_removed]", q.is_removed ? 1 : 0);

      data.append("questions[" + i + "][difficulty]", q.difficulty);
      q.answers.map((a, ind) => {
        data.append(
          "questions[" + i + "][answers][" + ind + "][title]",
          a.title
        );
        if(a.img){
          data.append("questions[" + i + "][answers][" + ind + "][image]", a.img);
        }
        data.append(
          "questions[" + i + "][answers][" + ind + "][isCorrect]",
          a.isCorrect
        );
      });
    });
    setData(data);
  };

  const [curriculum, setCurriculum] = useState(null);
  const [questions, setQuestions] = useState([
    {
      img: null,
      difficulty: "medium",
      title: "",
      chapter: null,
      topic: null,
      is_removed: false,
      answers: [
        { img: null, title: "a", isCorrect: false },
        { img: null, title: "b", isCorrect: false },
        { img: null, title: "c", isCorrect: false },
        { img: null, title: "d", isCorrect: false },
      ],
    },
  ]);

  function addQuestion() {
    setQuestions([
      ...questions,
      {
        img: null,
        difficulty: "medium",
        title: "",
        chapter: null,
        topic: null,
        is_removed: false,
        answers: [
          { img: null, title: "a", isCorrect: false },
          { img: null, title: "b", isCorrect: false },
          { img: null, title: "c", isCorrect: false },
          { img: null, title: "d", isCorrect: false },
        ],
      },
    ]);
  }

  function removeQuestion(index) {
    setQuestions(
      questions.filter((q, i) => {
        return i !== index;
      })
    );
  }

  // years

  function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 22
    var years = []
  
    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  return (
    <div className="page">
      <div className="header">
        <h1>Add Exam</h1>
      </div>

      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              sx={{ width: "100%" }}
              label="Name"
              {...register("name", { required: true })}
            />
            <div className="validationError">
              {errors.name?.type === "required" && "Name is required"}
            </div>
         
            <SearchSelectBox
              multiple={false}
              api={getCurriculums}
              register={register}
              value={curriculum}
              handleChange={(event, newValue) => {
                setCurriculum(newValue);
                setQuestions(questions.map(q => {
                  return {...q, chapter: null}
                }))
              }}
              label="Curriculum"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              sx={{ width: "100%" }}
            >
              <div style={{ width: "49%" }}>
              <FormControl fullWidth size="small">
                  <InputLabel id="YearLabel">Year</InputLabel>
                <Select
                size="small"
                    labelId="YearLabel"
                    sx={{ width: "100%" }}
                    label="Year"
                    {...register("year", { required: true })}
                  >
                   { generateArrayOfYears().map(year => {
                    return <MenuItem key={year} value={year}>{year}</MenuItem>
                   })

                   }
                    
                   
                  </Select>
                  </FormControl>
                <div className="validationError">
                  {errors.year?.type === "required" && "Year is required"}
                </div>
              </div>
              <div style={{ width: "49%" }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="mounthLabel">Mounth</InputLabel>

                  <Select
                    labelId="mounthLabel"
                    sx={{ width: "100%" }}
                    label="Mounth"
                    {...register("mounth", { required: true })}
                  >
                    <MenuItem value={"january"}>january</MenuItem>
                    <MenuItem value={"february"}>february</MenuItem>
                    <MenuItem value={"march"}>march</MenuItem>
                    <MenuItem value={"april"}>april</MenuItem>
                    <MenuItem value={"may"}>may</MenuItem>
                    <MenuItem value={"june"}>june</MenuItem>
                    <MenuItem value={"july"}>july</MenuItem>
                    <MenuItem value={"august"}>august</MenuItem>
                    <MenuItem value={"september"}>september</MenuItem>
                    <MenuItem value={"october"}>october</MenuItem>
                    <MenuItem value={"november"}>november</MenuItem>
                    <MenuItem value={"december"}>december</MenuItem>
                  </Select>
                </FormControl>

                <div className="validationError">
                  {errors.mounth?.type === "required" && "Mounth is required"}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                variant="contained"
                sx={{ marginRight: "10px" }}
                onClick={addQuestion}
              >
                <AddIcon />
              </IconButton>
              Questions
            </Typography>
          </Grid>
          
            
            {questions.map((q, index) => {
              return (
                <Grid item xs={6} key={index}>
                <QuestionAccordion
                  curriculumId={curriculum ? curriculum.id : null}
                  register={register}
                  errors={errors}
                  index={index}
                  addQuestion={addQuestion}
                  removeQuestion={removeQuestion}
                  question={q}
                  answers={q.answers}
                  handleChangeQuestion={(value) => {
                    setValue("questionText"+index, value)
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return { ...qu, title: value };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                  handleChangeQuestionImage={(value) => {
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return { ...qu, img: value };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                  handleChangeDifficulty={(value) => {
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return { ...qu, difficulty: value };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                  handleChangeAnswerText={(value, i) => {
                    setValue("questionAnswerText-"+index+"-"+i, value)
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return {
                            ...qu,
                            answers: q.answers.map((an, inx) => {
                              if (inx === i) {
                                return { ...an, title: value };
                              } else {
                                return an;
                              }
                            }),
                          };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                  handleChooseCorrectAnswer={(i) => {
                    setValue("questionAnswerCorrect-"+index, "correct")
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return {
                            ...qu,
                            answers: q.answers.map((an, inx) => {
                              if (inx === i) {
                                return { ...an, isCorrect: true };
                              } else {
                                return { ...an, isCorrect: false };
                              }
                            }),
                          };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                  handleChangeAnswerImg={(value, i) => {
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return {
                            ...qu,
                            answers: q.answers.map((an, inx) => {
                              if (inx === i) {
                                return { ...an, img: value };
                              } else {
                                return an;
                              }
                            }),
                          };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                  handleChangeQuestionTopic={(value) => {
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return { ...qu, topic: value };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                  handleChangeQuestionChapter={(value) => {
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return { ...qu, chapter: value, topic: null };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                  handleMarkRemoved={(value) => {
                    setQuestions(
                      questions.map((qu, ind) => {
                        if (ind === index) {
                          return { ...qu, is_removed: value };
                        } else {
                          return qu;
                        }
                      })
                    );
                  }}
                />
                </Grid>
              );
            })}
          

          <Grid item xs={12}>
            <ResponseHandler
              error={error}
              success={success}
              successMsg="Stored Successfully."
            />
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <FormButton txt="Create" loading={loading} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
