
import "./ChooseImage.css"
import CloseIcon from '@mui/icons-material/Close';
export default function ChooseImage(props){

    function handleFileSelected(e){
        const files = Array.from(e.target.files)
        props.handleSelectImage(files[0])
    }

    return(
        <div className={"ChooseImage"}>
            <input onChange={handleFileSelected} accept="image/*" type="file" />

        {!props.image && <div>+</div>}
        {props.image && (
          <>
            <img style={{ height: "100%", width: "100%", objectFit: "cover"}} src={props.image} />
            <CloseIcon
              onClick={() => {
                props.handleSelectImage(null)
              }}
              style={{
                position: "absolute",
                top: "15px",
                right: "20px",
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </>
        )}
      </div>
    )
}