import {Box, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import useCrud from "../../hooks/useCrud";
import { useParams } from "react-router";
import { useEffect } from "react";
import ResponseHandler from "../../shared-components/ResponseHandler";
import FormButton from "../../shared-components/FormButton";
import { editGrade, getGrade } from "../../api/grades";


export default function EditGrade(){

    const navigate = useNavigate();


    const {gradeId} = useParams()

    const {loading,error,success,setData} = useCrud(editGrade,() => {
        navigate("/grades")
    })

    const {response: gradeResponse,loading: gradeLoading,error: gradeError,setData: setGradeId} = useCrud(getGrade,() => {})

    useEffect(() => {
        setGradeId({id: gradeId})
    },[])

    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = data => {
        setData({data: data, id: gradeId})
    };

    return (
        <div className='page'>
            <div className='header'>
                <h1>Edit Grade</h1>
            </div>

            <Box component="form" autoComplete="off"  onSubmit={handleSubmit(onSubmit)}>

                { gradeResponse &&
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                        size="small"
                        defaultValue={gradeResponse.data.name}
                            sx={{width: "100%"}}
                            label="Name"
                            {...register("name", { required: true, maxLength: 20 })}
                        /> 
                        <div className="validationError">{errors.name?.type === 'required' && "Name is required"}</div>
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex",justifyContent: "end"}}>
                        <FormButton txt="Update" loading={loading} />
                    </Grid>

                    <Grid item xs={12} >
                        <ResponseHandler error={error} success={success} successMsg="Updated Successfully." />
                    </Grid>

                </Grid>
                }

                <ResponseHandler error={gradeError} loading={gradeLoading} />

            </Box>

        </div>
    )

}